﻿body {
	text-align: center;
	background-color: @white;
	font-family: 'Gotham SSm A', 'Gotham SSm B', 'Calibri', 'Arial Narrow', sans-serif;
}

#header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 76px;
	background-color: @white;
	text-align: left;
	z-index: 999;
	min-width: 320px;

	@media (min-width: @screen-xs-min) {
		height: 81px;
	}

	@media (min-width: @screen-sm-min) {
		height: 93px;
	}

	@media (min-width: @screen-md-min) {
		border-bottom: 2px solid @ev-red;
	}

	#usp-carousel {
		width: auto;
	}

	#header-trust {
		float: left;
		margin: 13px 0 0 9px;
		display: none;
	}
}

.header-wrap {
	position: relative;
	height: 44px;

	@media (min-width: @screen-sm) {
		margin: 0 auto;
		width: 750px;
		padding-left: 15px;
		padding-right: 15px;
	}

	@media (min-width: @screen-md) {
		width: 970px;
	}

	@media (min-width: @screen-lg) {
		width: 1170px;
	}
}

.logo {
	float: left;
	margin: 5px 0 0 15px;

	@media (min-width: @screen-xs-min) {
		margin-top: 5px;
	}

	@media (min-width: @screen-sm-min) {
		margin-top: 0;
		margin-left: 0;
	}

	@media (min-width: @screen-md-min) {
		margin-top: -1px;
	}

	img {
		height: 36px;
		width: 138px;

		@media (min-width: @screen-sm) {
			height: 50px;
			padding-top: 10px;
			width: 156px;
		}
	}

	&.tms {
		margin: 14px 0 0 10px;

		@media (min-width: @screen-sm) {
			margin: 15px 0 0 0;
		}

		img {
			height: 27px;
			padding-top: 0;

			@media (min-width: @screen-sm) {
				width: 220px;
				height: 45px;
			}
		}
	}
}

.header-options {
	display: inline-block;
	float: right;
	text-align: right;
	margin-top: -5px;
	margin-bottom: -7px;

	@media (min-width: @screen-sm-min) {
		margin-top: 0;
	}

	@media (min-width: @screen-md-min) {
		margin-top: -1px;
	}

	ul {
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			float: left;
			position: relative;
		}
	}
}

a.search, a.basket, a.login {
	display: inline-block;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAeCAMAAADjLDWuAAABjFBMVEUFMUn///8FMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUmlSgCfAAAAg3RSTlMAAAECAwQGBwgJCgwNDg8REhMZGxwdIiQlJikqLzAzNDc4Oz1CRUpMT1JUVldYWmBhZGZnaW1ucHFzdHV3e3yBgoOFhoeIio+Zn6KjpKaoqq6vsbKztLW4uru8vr/AwsPHyM7Q0dLT1NXW19jc3eHi5efp6uzu7/Dx8vP19vf5+/z9/jzbtrYAAAFnSURBVHgBfcn3ewtxHMDx70dvaFq0qCEt0drUqEEMEYPEjkGM2JucERIcktz7H/c8ySe5y92Tvn59GTW29/K7nzReFXe5EtXd0dwPUN+OO/Hf+ImoNxsGf5sPVPObV5jJuQufgfpc9DM+/Dpom66lWR8a6fDHPaimTY/ITA3euv2/CN/XmshLxocTvZ/wYZ+evmSh5uovwMuR2Lse7NS/CUdM7OUMFPSrMJ34rfBc/w9MJn49ePotsBO/Cr7q12B14qfhg/4T2JH4A1DSPwdXE1+GY/oz0EzHfjagPaVvHsMja+BTL+CG9H5TCwojkbdvwd91/Tc54N5E/1c+AK5J+EuuAPVTU51ec/Y3QHAofGNdAuD17eKdjwDtLxAc1u/Y7hF6v2X5MwiykTfO/koTgH/lPbbIsqfASX01Pju/sDuTko6xCnB64JV0pR4C+eEvo/eB88Nf3LvA/PAXp8R1a5EX56gl/wERT3qT9rHLVAAAAABJRU5ErkJggg==);
	background-position: center center;
	background-repeat: no-repeat;
	height: 54px;
	width: 50px;
	text-indent: -9999px;
	color: rgba(0,0,0,0);
}

a.search {
	background-size: 21px 20px;
}

a.basket {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAMAAAB61OwbAAABEVBMVEUFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkMs5e7AAAAWnRSTlMAAQIGBwkMDg8TFhkbHiAjJSgqMDk7PEBDRUhOT1BSVFdbXGBiZWprc3iBhIaMmJmcnaCiq6+0tbi/wMHDxMbHyMnKy8/c3uDh4uXm7O3u7/Hz9PX3+fr7/P5Ubog3AAABfElEQVR42s2Q11LCUBiEN9goIkhVNILSFBAl2BtFRAWxoCRm3/9B/DkmDgyj134Xe2ZPNjvJYpr8YJDHH3hN0vTid6IUov804I9t6rqecAMJMZsxP1ySlzbHXLiBC2XtyyQUZTGzAUUZQpG/B1gEAh9yWje068aeG9gz6jZvLJIfAZTk6EW0LpOTf5FkV4v0xJTQFt2C5GqTgZo8wZaYNobkaB4I83UBCFEIAQuvDAPzI3IIkmYQQIMZQGuSTQ3IsAEgaJKEXLEqrsBT0cVcblGOUxZEq5Q4Uk6Fz7T8P8Naps8pSEFVtA3DGPDccDjnQLStCiAVf5CCcEuylc1mX1gWFcp8EW05BYA+XiwMHLACRYUHwMp44Q3ltQ7JKw/ifPBA8DwwDpyQ7GhQrFPYhZrbmRm6+wWKQzGfa2puZ2Zfj+QRXJYexb5tr8rc3zMHrscXy/ghblJ4t9m/v+/TfrYpFDDBDmc40jBJ+o3THM9hmsD+nUWXp7O0+/4XVuuUxTiqfrkAAAAASUVORK5CYII=);
	background-size: 22px 20px;
	position: relative;
}

a.basket.collapsed {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAhJJREFUeNrsV91xgkAQBsZ3kgq0A0kFYgXSAVpB6ECsIFiBpIPzIc8xFUQ7kMe8QQe5m9nLfNnsCWHEvGRndmTWY/e7/ce/f/nwelKh+ZGet5qzPkqCnsYjMO7Rc3RLAHcdZYMBuBr9A/hzACOHPKak4pl91ly26FxqnjDZUXOt+dAGICMeO5S/dQQwc/xXUf8oJABGcTqwx83FnsizS8yBWxhHSq0XRhTv1OEuG4oTxfDYQfkRGtNU0IXdUwXWFUCN5jm5qYG+H3fs9xmdLUBfRDobni8GQMKEOWWrubGCxPot2XcUVEDOziQGQMiEmOUWwEworUs0gUpQDt2GwsAx6RBAZdGyODcsbJgfCcReOXR/dcJKCIEneAHjX5OyDXFEMo+dVS26K18vJCZWO/bHHLqWUf5Ozw8dKsF13iTmKzu78mkjOrMyqUjmQQ5Iclf8x9A5JbnVNbEAJC8MTSuTlD7shDWriAZ7tqY1/e4vhMG4f0HPG5YTIb89nwUFGPHohQPLhQUBzR0ASgCZQ+zXwkL7Yx8ohE5Vwq6noI+7dsJUyP5C6LSlBEC62RhkCgAmAoAEDGDpToVSrF0bUcEy1w6NZYfWzFtvJFzoxD0SOBTxUOxgRkitmbfemNwcCoPqG/mOL6MhylL8egouZPPzFY2fXJUTtIzT7ZWMx2xWdF7LM5oL+x6GK2pGTuOGPgUYAJj6jO9+3zVXAAAAAElFTkSuQmCC');
	background-size: 22px 20px;
}

a.search.collapsed {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAeCAMAAADjLDWuAAABjFBMVEURten///8RtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtem3L+UCAAAAg3RSTlMAAAECAwQGBwgJCgwNDg8REhMZGxwdIiQlJikqLzAzNDc4Oz1CRUpMT1JUVldYWmBhZGZnaW1ucHFzdHV3e3yBgoOFhoeIio+Zn6KjpKaoqq6vsbKztLW4uru8vr/AwsPHyM7Q0dLT1NXW19jc3eHi5efp6uzu7/Dx8vP19vf5+/z9/jzbtrYAAAFjSURBVHgBddPp1wphGMfxO88snoRCFhUpO1mykEWyUPYsZMm+01iiMKjm+4+jc9U9zX18337Oua5XPyUt2nXh9Tf6zxvbXSXF/qXGzVe/IvH5iBP1de8J93LtrG/2gU5tw1KVKp79APSKYc/78H2fLV8XVnzoZ7QnPOhklC7XhVfu1M/Bl1Va5eDRiSd92K1mq0DXFS/Ds7mIux5sE78GB1W0k1AX70DW8E3wRPwnpAxfA574EGzDl8Mn8S6sMDwLb8UfwlbD90JT/DRcMrwFh8VzMMhEuBAwSourB3DfmuH4U7iqJr5+CPW5ENvX4dfqqasqcDs55WV3gctK+4KLQO94eqwrT/0ACPZrV9Z5AF7caNx8BzD6CMEB8XFbPHRvNi55DEEl5MrZ0x4A8Lu101Zq8SPgmLiUKJTKO/JxmUMbOKHdKH4PqP312H+avwOcMVzn3gJKhuucJlcs03XOISv2BxASelhVarJ0AAAAAElFTkSuQmCC);
	background-size: 21px 20px;
}

a.login {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAnCAMAAABQShoEAAABWVBMVEURten///8RtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtelCSvnmAAAAcnRSTlMAAAECAwQGBwkKDA0ODxocHSAkKCksLS4wMTM1OTtAQUJHTU5QV11fYmRlZ2hqbXN3eHuAgYKDhoiKi4yRk5qbnKCmp6ittLa3vL2+wsTHyMnMzc/R09XW19nb3d7g4eTm6Onq6+zu8fT19vj5+vv8/f5KzkLFAAABcklEQVR42p3SR1NCQRCF0W4jQUVFjKCYc84YMeeImCNmROT+/4VlOfjejP1c+K3PYqZuk63g/OV76mK2js0s4lmAKup2QsWH+GnP64CWYCsqoyC0akQ0p6MZEZ3p6EhESR09iOhJR7ciiuvoQESzOpoWUbOOGkWUf20357kioj476mIZ5R9ZZj9XRhXuymeonvzeCgGVLCNK4RdlGnKWsFxiolACwAQFdgBgy58zBSAR0lH49fs+vFTb013NRYv46jVsR4HsJDf9ZUTlA3fZpwUs5D6FVfINVqfuHxSBY5EsKk05o1SpQqP4o1GFYn+hmEJp4GPjEb962MwAaYUywCDV/kZ+HgEyCsWBFqK0aV6Y24C4QkNAJ9Gdia6Ye4EhhVzHGCPaNdEmcwTHLoXId7JKNGGiYeaNE5+1nWc8j4ImqubCSY95dNu6WRcvsyqp/c0vImpN2zZrYhlR/SVU5yF2QlTQsXYPJFbaC9hELPQv9AmpjxqsW1+rZgAAAABJRU5ErkJggg==);

	&.logged-in {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAnCAMAAABQShoEAAACSVBMVEURten///8RtekRtekRtekRtekRtekRtekRten///8RtekRten///8RtekRten///8RtekRtekRten///8Rten///8RtekRtekRtekRten///8RtekRtekRtekRtekRtekRtelizvERtekRtekRtekRtekRtekRten///8RtekRtekRtekRtekRtekRtekRtekRtekRten///8RtekRtekRtekRtekRtekRtekRtekRten///////8RtekRtekRtekRtekRten///////8RtekRtekRten///8Rten///////8Rten///8Rten///8RtekRten///8RtekRtekRten///8RtekRten///8Rten///80wOwRtekRtekRtekRtekRtekRtekRten///8RtekRtekRtekRtekRtekRtekRtekRten///8RtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtem46fgRtekRtekduerE7foRtekRtekRtekRtek7wu0RtekRtekRten///8RtekcuOoyv+w1wOw4we06wu1w0/Kb4Pax5/i06Pi36fjS8fvU8vvg9fzjGyPjHSXkHyfkIyvlKC/lKDDmMDfmMTnmMzrmNz7nOUDnOkHoQknoRUzpS1LpTFLqUVfrWl/sZ2zta3Hudnvu+v3wiIz0oqX0pKf0qaz1sbT3vsD3v8H3/P75ysz5zM750dL619j73+D85uf85+j86en8/v/97O398PD98vL+9vb++vr//Pz//v7///8fqAjFAAAAhnRSTlMAAAECAwQGCQoKDA0NDg8VGhwdHiAjJCgpLCwtLjAxMzU4OTtAQUJHSU5QV19iZGVnaGltc3d4e4KDhoaHiIuMkZOXmZqbnJygoKWmpqenqK2xtLa3ur3Cw8TExcfIyczNz9HR09XW19nb3d7f4OTm6Onq6+zu8fT09fb29/j5+vv7/P3+/tSmwF0AAAIOSURBVHgBddP5X0xRGMfx55YyGksWaiyiGPti7FQWYSTKEtkXIUtoJMSIwYPsumQ3FrLvDUnk+5eZU3Pnnns79/Pbfb3er3uf1z3PIanMReWna/blDdHsmSRlKcfyu5xQ920cb7PbARWylF+NMtnSYCVaaEXzlGiPFZUqUdCKKpWoyooOKdFOK9qqRHlWNFeJRlnRCCVKOiCbvYlKRJNlNEFTo6RS02xJVKN014CjhqlKc6crUGoR+8l7PGaGJxRyUaodZQWYOYc8G4VZm5Ywh5kDWVbkrWaR300ZkyYO0rotY1G1V0Ye40gOTulD1G9qhTGax0SuMjYLnmKzMlcc5bJjuf19BT6BetU4ozPnASyIohnO5tIbvGtojSra7mgu1uHjbT2qfBRiPrvmSEdSueEmPt/Rdb0BBXSOeRplsFztlVrmoSX4Vq9He4vZYiVHE4Ukc/kXcONEMRrvCfP6H0bSdObxRBXSuF/w/gdOIvJAmFetmK9R5108k2hT3Fyowwf9UQRNT4R5+bftF1DP3auIcuLoatu4D7+HhXn+B4vbzy5lVifplt/CU90o/BtL5KVbZ76pKRwzj39iuSajgUFDXUdzu7ofwQrbjo8JGeoamsUX7zaiuIsN0bByQx1DyzO9/itKemh2RMnjVh9mDqwcm5yPlhefsKO3ZiBNVT6A/X01EynLXp/d1Xz6D/LUmd24OXcEAAAAAElFTkSuQmCC);
	}
}

form#header-search {
	background-color: #053149;
	margin: 0;
	position: absolute;
	top: 74px;
	width: ~"calc(100% - 30px)";
	z-index: 1001;
	left: 15px;

	.header-search-arrow {
		position: absolute;
		right: 115px;
		top: -7px;
		height: 9px;
		width: 18px;
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAJCAMAAAAIAYw9AAAADFBMVEUFMUkFMUkFMUkFMUkRZPVpAAAAA3RSTlMAgIFbSu8jAAAAJ0lEQVR4AV3IoQEAMAgDMDr+/3mKiEZmTjIlu84444wzdU/cGWfcBzSGAO4zyWEdAAAAAElFTkSuQmCC) no-repeat center center;
	}

	.header-search-inner {
		padding: 10px 15px;
	}

	input#header-search-tb {
		font-weight: @bold-font-weight;
	}
}

.autocomplete-suggestions b {
	font-weight: normal;
	color: @ev-blue;
}

.autocomplete-suggestions {
	min-width: 220px;
	padding: 0 0;
    text-align: left;
    cursor: default;
    border: 1px solid @light-grey;
    border-top: 0;
    background: @white;
    -webkit-box-shadow: -1px 1px 3px rgba(0,0,0,.1);
    -moz-box-shadow: -1px 1px 3px rgba(0,0,0,.1);
    box-shadow: -1px 1px 3px rgba(0,0,0,.1);
    /* core styles should not be changed */
    position: absolute;
    display: none;
    z-index: 9999;
    max-height: 254px;
    overflow: hidden;
    overflow-y: auto;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
	.font-size(1.1);

	@media (min-width: @screen-sm) {
		.font-size(1.2);
	}
}

.autocomplete-suggestion {
	padding: 6px 11px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    color: @dark-grey;

	&:hover {
		cursor: pointer;
	}
}

.autocomplete-suggestion.selected {
    background: #f0f0f0;
}

.ui-autocomplete-term {
	font-weight: normal;
	color: @ev-blue;
}

.ui-autocomplete {
	min-width: 220px;
	padding: 0 0;
	text-align: left;
	cursor: default;
	border: 1px solid @light-grey;
	border-top: 0;
	background: @white;
	-webkit-box-shadow: -1px 1px 3px rgba(0,0,0,.1);
	-moz-box-shadow: -1px 1px 3px rgba(0,0,0,.1);
	box-shadow: -1px 1px 3px rgba(0,0,0,.1);
	/* core styles should not be changed */
	position: absolute;
	display: none;
	z-index: 9999;
	max-height: 254px;
	overflow: hidden;
	overflow-y: auto;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	.font-size(1.1);

	@media (min-width: @screen-sm) {
		.font-size(1.2);
	}
}

.ui-menu-item {
	padding: 6px 11px;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	color: @dark-grey;

	&:hover {
		cursor: pointer;
		background-color: @grey;
	}
}

.ui-menu-item.selected {
	background: #f0f0f0;
}

#promoCodeContainer {
	#promocode-Value {
		margin-left: -5px;
	}
}

span[name="basketTotalItemValue"] {
	margin-left: -5px;
}

strong {
	span[name="basketTotalItemValue"] {
		margin-left: 0px;
	}
}

#basket-popout {
	position: absolute;
	top: 74px;
	right: 15px;
	background-color: @carousel-color;
	width: 320px;
	color: @white;
	box-shadow: 0 4px 8px -4px rgba(0,0,0,0.8);
	z-index: 1002;

	#voucher-details {
		#promocode-Value {
			margin-left: -5px;
		}
	}

	.basket-popout-arrow {
		position: absolute;
		right: 66px;
		top: -7px;
		height: 9px;
		width: 18px;
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAJCAMAAAAIAYw9AAAADFBMVEUFMUkFMUkFMUkFMUkRZPVpAAAAA3RSTlMAgIFbSu8jAAAAJ0lEQVR4AV3IoQEAMAgDMDr+/3mKiEZmTjIlu84444wzdU/cGWfcBzSGAO4zyWEdAAAAAElFTkSuQmCC) no-repeat center center;
	}

	h3 {
		text-transform: capitalize;
		display: block;
		color: @white;
		border-bottom: 1px solid #1f536b;
		padding: 20px 15px;
		margin: 0;
	}

	.basketContentEmpty {
		text-transform: none !important;
	}

	.basket-popout-product-container {
		background-color: @white;
		color: @black;
		height: 210px;
		overflow-y: scroll;
	}

	.basket-popout-product {
		margin: 10px 0;
		.font-size(1.5);
		.line-height(2.2);
		border-bottom: 1px solid @grey;

		.attr-cont {
			.line-height(1.2);
		}

		.attr {
			.font-size(0.9);
		}

		.basket-offer {
			display: inline-block;
			.font-size(1.2);
			font-weight: bold;
			padding: 0px 4px;
		}

		.row {
			margin-bottom: 5px;
		}
	}

	.basket-popout-product-img {
		padding: 8px;
		text-align: center;
		background-color: @white;
		height: 66px;
		width: 66px;

		img {
			max-height: 100%;
		}
	}

	.basket-popout-subtotal {
		border-top: 1px solid #1f536b;
		margin: 0 -15px;
		padding: 20px 15px;
	}

	.basket-popout-btn {
		margin-top: 20px;
	}

	a.basket-popout-close {
		height: 14px;
		width: 15px;
		position: absolute;
		display: block;
		right: 15px;
		top: 21px;
		background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDE1MkE0RDRBREYzMTFFNDhFMTZEQzVFMUYwMjNCMTAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDE1MkE0RDVBREYzMTFFNDhFMTZEQzVFMUYwMjNCMTAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEMTUyQTREMkFERjMxMUU0OEUxNkRDNUUxRjAyM0IxMCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEMTUyQTREM0FERjMxMUU0OEUxNkRDNUUxRjAyM0IxMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvJA2zwAAAFISURBVHjajJIxSwNBEIX3ThErQQUtUthZpLVIoSiCCgkhTVDxN1ipYCHXxkbFyjpoI6YUYiGCmJDCP2BhpYVFLARFUGzW78Egl+OSePDd7M28d7vMTjBab8845w5g460w0XZ9nrGr10nCOeyGvPZhERoUpvoYVW+YviLzKtzBNLQQZLsYlW+ZTvq1kKN+sMjDJWSgiTCXMOZsx4zp8vjetbNj8UUow6m0cINh2YxL+oZxOJPO9C7w3sd3CAhHsAU/cAKbMATHsIPxz9Bhjv1kTw2JpSJMlaQu7NFcH4s+TZB27EPY/s+xw5hxgFA14ycUEWpdtG/1oYpusGNnEsOsL6Ck5kMB433iqurWcV3VOvXvgPEcscQCvMAKhYcuQ3Jtd60hKenYNTM+wlya0WZB+VnTSV+TOYJbmEfw1Gu2qT9LZ/roV4ABACZygXWxDT0iAAAAAElFTkSuQmCC') no-repeat;

		@media (max-width: @screen-xs) {
			top: 18px;
		}
	}
}

.basket-popout-count {
	position: absolute;
	border-radius: 12px;
	text-align: center;
	padding: 2px 5px 3px;
	min-height: 15px;
	min-width: 15px;
	background-color: @ev-red;
	color: @white !important;
	border: 2px solid @white;
	.font-size(0.8);
	.line-height(0.8);
	top: 10px;
	right: 4px;

	@media (min-width: @screen-sm) {
		top: 1px;
		right: -8px;
		padding: 2px 6px 3px;
		min-height: 15px;
		min-width: 15px;
		.font-size(1.1);
		.line-height(1.1);
	}
}

.usp-bar {
	background-color: @carousel-color;
	color: @white;
	clear: both;
	border-top: 2px solid @white;
	text-align: left;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.5);

	.usp-list {
		padding-right: 0;
		float: left;
		width: 100%;
	}

	ul {
		padding: 0;
		margin: 0;

		li {
			list-style-type: none;
			display: inline-block;
			background-position: left center;
			background-repeat: no-repeat;
			height: 42px;
			line-height: 42px;
			margin-right: 8px;
			.font-size(1.2);
			text-transform: uppercase;

			a {
				padding: 15px 0 15px 26px;
				color: @white;
			}

			&:last-of-type {
				margin-right: 0;
			}

			&.envirocash {
				margin-left: -4px;
				background-size: 51px 200px;
				background-image: url('@{img-cdn2}global/icons/svg/simple/red/envirocash.svg');

				a {
					margin-left: 27px;
				}
			}

			&.happy-cust {
				background-size: 51px 200px;
				margin-left: -7px;
				background-image: url('@{img-cdn}icons/red/icons_usp_happycustomers.svg');
			}

			&.damaged-phones {
				background-size: 51px 200px;
				margin-left: -7px;
				background-image: url('@{img-cdn}icons/red/icons_usp_damagedphones.svg');

				a {
					margin-left: 20px;
				}
			}

			&.enviropack {
				background-size: 51px 200px;
				background-image: url('@{img-cdn}icons/red/icons_usp_freepostage.svg');

				a {
					margin-left: 26px;
				}
			}

			&.fast-payment {
				background-size: 51px 200px;
				background-image: url('@{img-cdn}icons/red/icons_usp_fastpayment.svg');

				a {
					margin-left: 24px;
				}
			}
		}
	}

	#usp-carousel {
		ul {
			text-align: center;
		}
	}
}

.phone-number {
	padding: 0 0 0 18px;
	.font-size(2.6);
	.line-height(1.2);

	a {
		color: @white;
		text-decoration: none;
	}

	@media (min-width: @screen-md) {
		padding: 10px 0 0;
	}
}

span.phone-hours {
	.font-size(1.0);
	.line-height(1.0);
	font-weight: @bold-font-weight;
}

.buy-sell-bar {
	background-color: @light-grey;
	color: @white;
	clear: both;
	border-bottom: 2px solid @ev-red;
	//margin-top: 0px;
	text-align: left;

	@media (min-width: @screen-md-min) {
		border-top: solid 1px @white;
	}

	.tab-back {
		height: 26px;
		background-color: @ev-red;
		width: 50%;
		position: absolute;
		left: -34px;

		@media (min-width: @screen-xs) {
			height: 31px;
		}
	}

	a {
		color: @white;
	}

	.slant-back {
		background-color: @light-grey;
		width: 40px;
		height: 26px;
		float: left;

		@media (min-width: @screen-xs) {
			height: 31px;
		}
	}

	.tab-text {
		padding: 6px 0 6px 0;
		text-transform: uppercase;
		font-weight: @bold-font-weight;

		a {
			display: block;
		}
	}

	.sell {
		.tab-text {
			margin-left: 34px;
		}
	}

	.sell, .buy {
		position: relative;
		width: 50%;
		margin-left: -34px;
		float: left;
	}

	.sell:after {
		content: '';
		line-height: 0;
		font-size: 0;
		width: 0;
		height: 0;
		border-top: 0 solid transparent;
		border-bottom: 26px solid @ev-red;
		border-left: 0 solid transparent;
		border-right: 37px solid transparent;
		position: absolute;
		top: 0;
		right: -37px;

		@media (min-width: @screen-xs) {
			border-bottom: 31px solid @ev-red;
		}
	}

	.buy {
		margin-left: 9px;
		width: 100px;
	}

	.buy:before {
		content: '';
		line-height: 0;
		font-size: 0;
		width: 0;
		height: 0;
		border-top: 0 solid @light-grey;
		border-bottom: 26px solid transparent;
		border-left: 0 solid transparent;
		border-right: 37px solid @light-grey;
		position: absolute;
		top: 0;
		left: -46px;


		@media (min-width: @screen-xs) {
			border-bottom: 31px solid transparent;
		}
	}
}

span.phone-hours {
	.font-size(1.0);
	.line-height(1.0);
	font-weight: @bold-font-weight;
}

.wrapper {
	max-width: @site-max-width;
	margin: 0 auto;
	text-align: left;
	padding-left: 15px;
	padding-right: 15px;
}

#content {
	padding-top: 121px;
	text-align: left;
}

.social-fb {
	height: 39px;
	width: 37px;
	background: url("@{img-cdn}logos/facebook-icon.jpg") no-repeat center center;
	background-size: contain;
	display: inline-block;
	margin-right: 8px;
	margin-top: 4px;
}

.social-twitter {
	height: 39px;
	width: 37px;
	background: url("@{img-cdn}logos/twitter-icon.jpg") no-repeat center center;
	background-size: contain;
	display: inline-block;
	margin-right: 8px;
	margin-top: 4px;
}

.social-instagram {
	height: 39px;
	width: 37px;
	background: url("@{img-cdn}logos/instagram-icon.jpg") no-repeat center center;
	background-size: contain;
	display: inline-block;
	margin-top: 4px;
}

.iso-logos {
	width: 300px;
}

#social {
	margin-top: 30px;

	@media (min-width: @screen-sm) {
		margin-top: 50px;
	}
}

#footer {
	background-color: @ev-blue;
	.font-size(1.4);
	color: @white;
	padding-bottom: 20px;
	margin-top: 25px;

	a:hover {
		text-decoration: underline;
	}

	ul {
		margin-bottom: 30px;
		padding: 0;

		li {
			list-style-type: none;
			margin: 0 -15px;

			a {
				display: block;
				border-bottom: 1px solid @white;
				color: @white;
				padding: 10px 0;
				text-transform: uppercase;
			}
		}
	}

	.footer-logo {
		a {
			color: @white;
		}

		img {
			width: 194px;
			height: 74px;
		}
	}

	.copy {
		margin-top: 10px;
		.font-size(1.0);
	}
}

.content-container {
	padding-top: 10px;

	@media (min-width: @screen-sm) {
		padding-top: 30px;
	}
}

.page-heading {
	h1 {
		margin: 0;
	}

	h3 {
		margin: 0;
	}

	margin: 15px 0 20px 0;

	@media (min-width: @screen-xs) {
		margin: 30px 0 30px 0;
	}
}

@media (min-width: 630px) {
	#header {
		#header-trust {
			display: block;
		}
	}
}

@media (min-width: @screen-xs) {
	#basket-popout {
		top: 79px;
		right: 15px;
	}

	form#header-search {
		top: 79px;
	}
}

@media (min-width: @screen-sm) {

	#header {

		&.tms {
			height: 75px;
		}

		#header-trust {
			margin: 20px 0 0 15px;
		}
	}

	form#header-search {
		top: 93px;

		.header-search-arrow {
			right: ~"calc(50% - 170px)";
		}
	}

	.header-options {
		width: auto;
		padding-top: 10px;
		margin-bottom: 0;
	}

	#basket-popout {
		top: 93px;
		right: ~"calc(50% - 364px)";

		.basket-popout-arrow {
			right: 76px;
		}
	}

	#content {
		padding-top: 135px;

		&.tms {
			padding-top: 75px;
		}
	}

	a.search, a.basket, a.login {
		height: 37px;
		width: 32px;
	}

	a.login {
		margin-left: 25px;
		width: 36px;
		height: 39px;
	}

	a.search {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAeCAMAAADjLDWuAAABjFBMVEUFMUn///8FMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUmlSgCfAAAAg3RSTlMAAAECAwQGBwgJCgwNDg8REhMZGxwdIiQlJikqLzAzNDc4Oz1CRUpMT1JUVldYWmBhZGZnaW1ucHFzdHV3e3yBgoOFhoeIio+Zn6KjpKaoqq6vsbKztLW4uru8vr/AwsPHyM7Q0dLT1NXW19jc3eHi5efp6uzu7/Dx8vP19vf5+/z9/jzbtrYAAAFnSURBVHgBfcn3ewtxHMDx70dvaFq0qCEt0drUqEEMEYPEjkGM2JucERIcktz7H/c8ySe5y92Tvn59GTW29/K7nzReFXe5EtXd0dwPUN+OO/Hf+ImoNxsGf5sPVPObV5jJuQufgfpc9DM+/Dpom66lWR8a6fDHPaimTY/ITA3euv2/CN/XmshLxocTvZ/wYZ+evmSh5uovwMuR2Lse7NS/CUdM7OUMFPSrMJ34rfBc/w9MJn49ePotsBO/Cr7q12B14qfhg/4T2JH4A1DSPwdXE1+GY/oz0EzHfjagPaVvHsMja+BTL+CG9H5TCwojkbdvwd91/Tc54N5E/1c+AK5J+EuuAPVTU51ec/Y3QHAofGNdAuD17eKdjwDtLxAc1u/Y7hF6v2X5MwiykTfO/koTgH/lPbbIsqfASX01Pju/sDuTko6xCnB64JV0pR4C+eEvo/eB88Nf3LvA/PAXp8R1a5EX56gl/wERT3qT9rHLVAAAAABJRU5ErkJggg==);
		background-size: 31px 30px;
	}

	a.search.collapsed {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAeCAMAAADjLDWuAAABjFBMVEURten///8RtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtekRtem3L+UCAAAAg3RSTlMAAAECAwQGBwgJCgwNDg8REhMZGxwdIiQlJikqLzAzNDc4Oz1CRUpMT1JUVldYWmBhZGZnaW1ucHFzdHV3e3yBgoOFhoeIio+Zn6KjpKaoqq6vsbKztLW4uru8vr/AwsPHyM7Q0dLT1NXW19jc3eHi5efp6uzu7/Dx8vP19vf5+/z9/jzbtrYAAAFjSURBVHgBddPp1wphGMfxO88snoRCFhUpO1mykEWyUPYsZMm+01iiMKjm+4+jc9U9zX18337Oua5XPyUt2nXh9Tf6zxvbXSXF/qXGzVe/IvH5iBP1de8J93LtrG/2gU5tw1KVKp79APSKYc/78H2fLV8XVnzoZ7QnPOhklC7XhVfu1M/Bl1Va5eDRiSd92K1mq0DXFS/Ds7mIux5sE78GB1W0k1AX70DW8E3wRPwnpAxfA574EGzDl8Mn8S6sMDwLb8UfwlbD90JT/DRcMrwFh8VzMMhEuBAwSourB3DfmuH4U7iqJr5+CPW5ENvX4dfqqasqcDs55WV3gctK+4KLQO94eqwrT/0ACPZrV9Z5AF7caNx8BzD6CMEB8XFbPHRvNi55DEEl5MrZ0x4A8Lu101Zq8SPgmLiUKJTKO/JxmUMbOKHdKH4PqP312H+avwOcMVzn3gJKhuucJlcs03XOISv2BxASelhVarJ0AAAAAElFTkSuQmCC);
		background-size: 31px 30px;
	}

	a.basket {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAMAAAB61OwbAAABEVBMVEUFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkFMUkMs5e7AAAAWnRSTlMAAQIGBwkMDg8TFhkbHiAjJSgqMDk7PEBDRUhOT1BSVFdbXGBiZWprc3iBhIaMmJmcnaCiq6+0tbi/wMHDxMbHyMnKy8/c3uDh4uXm7O3u7/Hz9PX3+fr7/P5Ubog3AAABfElEQVR42s2Q11LCUBiEN9goIkhVNILSFBAl2BtFRAWxoCRm3/9B/DkmDgyj134Xe2ZPNjvJYpr8YJDHH3hN0vTid6IUov804I9t6rqecAMJMZsxP1ySlzbHXLiBC2XtyyQUZTGzAUUZQpG/B1gEAh9yWje068aeG9gz6jZvLJIfAZTk6EW0LpOTf5FkV4v0xJTQFt2C5GqTgZo8wZaYNobkaB4I83UBCFEIAQuvDAPzI3IIkmYQQIMZQGuSTQ3IsAEgaJKEXLEqrsBT0cVcblGOUxZEq5Q4Uk6Fz7T8P8Naps8pSEFVtA3DGPDccDjnQLStCiAVf5CCcEuylc1mX1gWFcp8EW05BYA+XiwMHLACRYUHwMp44Q3ltQ7JKw/ifPBA8DwwDpyQ7GhQrFPYhZrbmRm6+wWKQzGfa2puZ2Zfj+QRXJYexb5tr8rc3zMHrscXy/ghblJ4t9m/v+/TfrYpFDDBDmc40jBJ+o3THM9hmsD+nUWXp7O0+/4XVuuUxTiqfrkAAAAASUVORK5CYII=);
		margin-left: 25px;
		background-size: 32px 30px;
	}

	a.basket.collapsed {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAeCAYAAABNChwpAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAhJJREFUeNrsV91xgkAQBsZ3kgq0A0kFYgXSAVpB6ECsIFiBpIPzIc8xFUQ7kMe8QQe5m9nLfNnsCWHEvGRndmTWY/e7/ce/f/nwelKh+ZGet5qzPkqCnsYjMO7Rc3RLAHcdZYMBuBr9A/hzACOHPKak4pl91ly26FxqnjDZUXOt+dAGICMeO5S/dQQwc/xXUf8oJABGcTqwx83FnsizS8yBWxhHSq0XRhTv1OEuG4oTxfDYQfkRGtNU0IXdUwXWFUCN5jm5qYG+H3fs9xmdLUBfRDobni8GQMKEOWWrubGCxPot2XcUVEDOziQGQMiEmOUWwEworUs0gUpQDt2GwsAx6RBAZdGyODcsbJgfCcReOXR/dcJKCIEneAHjX5OyDXFEMo+dVS26K18vJCZWO/bHHLqWUf5Ozw8dKsF13iTmKzu78mkjOrMyqUjmQQ5Iclf8x9A5JbnVNbEAJC8MTSuTlD7shDWriAZ7tqY1/e4vhMG4f0HPG5YTIb89nwUFGPHohQPLhQUBzR0ASgCZQ+zXwkL7Yx8ohE5Vwq6noI+7dsJUyP5C6LSlBEC62RhkCgAmAoAEDGDpToVSrF0bUcEy1w6NZYfWzFtvJFzoxD0SOBTxUOxgRkitmbfemNwcCoPqG/mOL6MhylL8egouZPPzFY2fXJUTtIzT7ZWMx2xWdF7LM5oL+x6GK2pGTuOGPgUYAJj6jO9+3zVXAAAAAElFTkSuQmCC');
		background-size: 32px 30px;
	}

	.social-fb {
		height: 50px;
		width: 47px;
		margin-right: 12px;
	}

	.social-twitter {
		height: 50px;
		width: 47px;
		margin-right: 12px;
	}

	.social-instagram {
		height: 50px;
		width: 47px;
	}

	#footer {
		padding-top: 20px;
		text-align: left;
		min-height: 132px;

		ul {
			margin-left: -16px;

			li {
				display: inline-block;
				margin: 0 0 10px 16px;

				a {
					border-bottom: 0;
					border-right: 1px solid @white;
					padding: 0 16px 0 0;
				}

				&:first-of-type a {
					padding-left: 0;
				}

				&:last-of-type a {
					border-right: 0;
				}
			}
		}

		.footer-logo {
			text-align: right;

			img {
				width: 194px;
				height: 44px;
			}
		}

		.copy {
			margin-right: 14px;
			.font-size(1.4);
		}
	}
}


@media (min-width: @screen-md) {
	#header {

		&.tms {
			height: 110px;
		}

		#usp-carousel {
			width: 355px;
		}
	}

	.header-wrap {
		height: 56px;
	}

	#content {
		padding-top: 177px;
	}

	#basket-popout {
		top: 91px;
		right: ~"calc(50% - 484px)";

		.basket-popout-arrow {
			right: 21px;
		}
	}

	form#header-search {
		top: 91px;

		.header-search-arrow {
			right: ~"calc(50% - 345px)";
		}
	}

	.usp-bar {
		.usp-list {
			width: auto;
		}

		#usp-carousel {
			ul {
				text-align: left;
			}
		}
	}
}

@media (min-width: @screen-lg) {

	#header {
		#usp-carousel {
			width: auto;
		}
	}

	#basket-popout {
		top: 91px;
		right: ~"calc(50% - 584px)";

		.basket-popout-arrow {
			right: 21px;
		}
	}

	a.login {
		margin-left: 32px;
	}

	form#header-search {
		clear: none;
		display: block !important;
		position: absolute;
		visibility: visible;
		background-color: @white;
		z-index: 999;
		width: 300px;
		top: 43px;
		left: ~"calc(50% + 145px)";

		.header-search-arrow {
			display: none;
		}

		.header-search-inner {
			padding: 0;
			position: relative;
		}

		input#header-search-tb {
			width: 260px;
			float: right;
			height: 40px;
		}

		.btn-header-search, .btn-header-search:active, .btn-header-search:hover, .btn-header-search:focus {
			margin-left: -1px;
			background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAgdJREFUeNqsls8rRFEUx98b40eNsFF+jImwZYGksKDMejZKFrNBiq3xB7CjaBZSJCULGzsbpCQ/sjI0hQWzMaGI/By/nu/Jw3HNfb+aU5/p3fvOud+599x77lM1TVOSmAv4QTuoBYUgF1yAfbAE5sGNYtVISKAJHGjmdgtCIC3JGP8QOwbAm2bPVkGOHaGQMMAdGAX1wAPcoBz0gmPBdwdkWRFqFGayBYoMAkl0CHywmDEzIRVEWMA2yLSy7mCQxb2CSiMhP3N+BF6LIt+ssvgZI6Ep5hi2KUI0sPgb2S6knyhzbHYgREt/xsaoSuZHB7OUHas9xb5pQpxPVgHcrH2vODMelykTirO216FQMXuOy4RirN3iQIRqYB1rn8qEVli7x4FQEGToz1Rwz2VF1QcSbNcEbew4OnOXLLbPrASFmfMDaLUgkg92WdwJyJBcOz9CVH2PhHIyDLIlIgEQEwrr+vdhTWYqe1EB1kAJe3+n5zAKXvRd2QbKJPlaAJ2qqr6bXXwFYNPGXXQNFoW+eZqZlRvWBTrAoYEA1bQRkKeXoHHh/ZwopkqT92XVoEY/kB5wBSJgAzzxcUAY9LO+WdD1s4wOiujvlv27MvSnJ4SZ0bXhSqkQE5sUxKapP6VCTGyaCS3T94RbSbEhJzR4t543ym0Afc9mm8FsUKPvRcpNOnwS1P4UYACFBzwZYTciJgAAAABJRU5ErkJggg==);
			background-repeat: no-repeat;
			background-position: center center;
			color: transparent;
			height: 40px;
			width: 40px;
		}
	}
}

.more-container {
	margin-bottom: 20px;

	p {
		line-height: 2rem;
	}

	.more-hide {
		overflow: hidden;
		height: 4rem;
	}

	.more-show {
		height: auto;
	}
}

.headline-bar {

	h4 {
		display: inline-block;
	}

	h3 {
		display: inline-block;
		margin-bottom: 0;
	}

	a {
		margin: 0 0 10px 20px;
		display: inline-block;
	}
}

#about-us-usps {
	.block-grid-item {
		margin: -65px 0 0 0;
		padding-top: (65 + (@grid-gutter-width/2));
		padding-left: (@grid-gutter-width/2);
		padding-right: (@grid-gutter-width/2);
		padding-bottom: (@grid-gutter-width/2);
	}
}

#usps {
	margin-bottom: 25px;

	h3 {
		.font-size(1.7);
		font-weight: @bold-font-weight;
	}

	.usp-container {
		text-align: left;
		clear: both;
		margin-bottom: 40px;

		@media (min-width: @screen-sm) {
			text-align: center;
			margin-bottom: 0;
			clear: none;
		}

		.icon {
			margin: -10px 0;
			width: 60px;
			height: 60px;
			background-size: 60px;
			float: left;

			@media (min-width: @screen-sm) {
				margin: 30px auto;
				width: 150px;
				height: 150px;
				background-size: 150px;
				float: none;
			}

			&.envirocash {
				background-image: url('@{img-cdn2}global/icons/svg/rendered/envirocash.svg');
			}

			&.happy-customers {
				background-image: url('@{img-cdn2}global/icons/svg/rendered/happycustomer.svg');
			}

			&.damaged-phones {
				background-image: url('@{img-cdn2}global/icons/svg/rendered/sellit.svg');
			}

			&.free-postage {
				background-image: url('@{img-cdn2}global/icons/svg/rendered/sendit.svg');
			}

			&.fast-payment {
				background-image: url('@{img-cdn2}global/icons/svg/rendered/spendit.svg');
			}
		}

		.description {
			margin-left: 86px;

			@media (min-width: @screen-sm) {
				margin-left: 0;
			}
		}
	}
}

.head-with-tail {
	position: relative;
    background-color: @ev-blue;
    color: @white;
	font-weight: normal;
	.font-size(1.8);
	.line-height(1.8);
    padding: 20px 40px;
	margin: 0 0 20px 0;
	text-transform: uppercase;
    display: inline-block;

	&:after {
		content: '';
		line-height: 0;
		font-size: 0;
		width: 0;
		height: 0;
		border-top: 0 solid @ev-blue;
		border-bottom: 19px solid transparent;
		border-left: 32px solid @ev-blue;
		border-right: 0 solid transparent;
		position: absolute;
		bottom: -19px;
		left: 20px;
	}

	@media (min-width: @screen-xs) {
		.font-size(2.2);
	}
}

.apple-remove{
    border:3px solid @ev-red;
}

.head-with-tail2 {
	position: relative;
    background-color: @ev-red;
    color: @white;
	font-weight: normal;
	.font-size(2.0);
	.line-height(1.8);
    padding: 20px 40px;
	margin: 0 0 20px 0;
	text-transform: uppercase;
    display: inline-block;

	&:after {
		content: '';
		line-height: 0;
		font-size: 0;
		width: 0;
		height: 0;
		border-top: 0 solid @ev-red;
		border-bottom: 19px solid transparent;
		border-left: 32px solid @ev-red;
		border-right: 0 solid transparent;
		position: absolute;
		bottom: -19px;
		left: 20px;
	}

	@media (min-width: @screen-xs) {
		.font-size(2.2);
	}
}

.bottom-margin {
	margin-bottom: 20px;
}

.anchor {
	margin-top: -80px;
	height: 80px;

	@media (min-width: @screen-sm) {
		margin-top: -100px;
		height: 100px;
	}

	@media (min-width: @screen-md) {
		margin-top: -150px;
		height: 150px;
	}
}

.sky-bar {
	background-color: @grey;
	min-height: 146px;

	.container {
		text-align: center;
	}

	
	@media (min-width: @screen-md) {
		height: 100px;
		min-height: 0px;
	}

	img {
		display: inline-block;
		height: 50px;
		width: 80px;
		margin-top: 20px;

		@media (min-width: @screen-md) {
			height: 77px;
			width: 111px;
			margin-top: 0;
		}
	}

	h3 {
		display: inline-block;
		text-transform: none;
		margin: 20px 0 20px 0;
		.font-size(1.8);
		font-family: SkyTextRegular,helvetica,arial;
		font-weight: normal;
		color: #282828;

		@media (min-width: @screen-md) {
			.font-size(2.6);
			margin: 32px 0 0 77px;
		}
	}
}

.envirophone {
	.big-search {
		h3 {
			margin-top: 50px;
		}

		#Searchform {
			margin-top: 20px;
		}
	}
}

.affiliate-jumbotron {
	padding: 30px;
	margin-bottom: 0;

	.lenovo-logo {
		height: 50px;
		margin: 5px;
	}

	.charity-logo {
		max-height: 100px;
		max-width: 250px;
		margin: 5px;
	}
}