﻿#home-search {
	margin: 10px 0 25px 0;

	@media (min-width: @screen-xs) {
		margin: 20px 0 25px 0;
	}

	@media (min-width: @screen-sm) {
		margin: 20px 0 0 0;
	}

	@media (min-width: @screen-md) {
		margin: 30px 0 0 0;
	}

	#search-banner {
		margin: 0;

		@media (min-width: @screen-xs) {
			margin-top: -20px;
		}

		@media (min-width: @screen-md) {
			margin-top: -40px;
		}

		@media (min-width: @screen-lg) {
			margin-top: -70px;
		}

		img {
			margin: auto;
		}
	}
}

.big-search {
	h1, h3 {
		font-weight: @headline-font-weight;
		.font-size(2.8);
		text-transform: none;

		@media (min-width: @screen-sm) {
			.font-size(3.4);
		}

		@media (min-width: @screen-md) {
			.font-size(4.1);
		}
	}

	.sss {
		margin: 0 0 20px 0;
		text-align: center;

		.sss-container {
			display: inline;
			margin-right: 8px;

			h2 {
				.font-size(1.5);
				font-weight: @h3-font-weight;
				display: inline;
			}

			.sss-detail {
				.font-size(1.2);
				display: inline;
			}
		}
	}

	.search-row {
		margin-bottom: 10px;
	}

	#search-container {
		width: 80%;
		float: left;

		#SearchText {
			border: 1px solid @ev-red;
			height: 50px;
			.font-size(1.1);

			@media (min-width: @screen-sm) {
				.font-size(1.9);
				height: 60px;
			}

			@media (min-width: @screen-md) {
				.font-size(2.3);
				height: 60px;
			}
		}
	}

	#search-btn {
		float: left;
		height: 50px;
		width: 20%;
		text-transform: uppercase;
		font-weight: @h1-font-weight;
		.font-size(1.4);
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAgdJREFUeNqsls8rRFEUx98b40eNsFF+jImwZYGksKDMejZKFrNBiq3xB7CjaBZSJCULGzsbpCQ/sjI0hQWzMaGI/By/nu/Jw3HNfb+aU5/p3fvOud+599x77lM1TVOSmAv4QTuoBYUgF1yAfbAE5sGNYtVISKAJHGjmdgtCIC3JGP8QOwbAm2bPVkGOHaGQMMAdGAX1wAPcoBz0gmPBdwdkWRFqFGayBYoMAkl0CHywmDEzIRVEWMA2yLSy7mCQxb2CSiMhP3N+BF6LIt+ssvgZI6Ep5hi2KUI0sPgb2S6knyhzbHYgREt/xsaoSuZHB7OUHas9xb5pQpxPVgHcrH2vODMelykTirO216FQMXuOy4RirN3iQIRqYB1rn8qEVli7x4FQEGToz1Rwz2VF1QcSbNcEbew4OnOXLLbPrASFmfMDaLUgkg92WdwJyJBcOz9CVH2PhHIyDLIlIgEQEwrr+vdhTWYqe1EB1kAJe3+n5zAKXvRd2QbKJPlaAJ2qqr6bXXwFYNPGXXQNFoW+eZqZlRvWBTrAoYEA1bQRkKeXoHHh/ZwopkqT92XVoEY/kB5wBSJgAzzxcUAY9LO+WdD1s4wOiujvlv27MvSnJ4SZ0bXhSqkQE5sUxKapP6VCTGyaCS3T94RbSbEhJzR4t543ym0Afc9mm8FsUKPvRcpNOnwS1P4UYACFBzwZYTciJgAAAABJRU5ErkJggg==);
		background-repeat: no-repeat;
		background-position: center center;

		@media (min-width: @screen-sm) {
			background-image: none;
			height: 60px;
			.font-size(1.8);
		}

		@media (min-width: @screen-md) {
			height: 60px;
			.font-size(2.2);
		}
	}
}

#popular-phones-container {
	position: relative;
	margin: 0 40px 30px 40px;

	#popular-phones {
		position: relative;

		.owl-theme, .owl-controls {
			border-top: 0;
		}

		.owl-controls .owl-buttons div {
			position: absolute;
		}

		.owl-controls .owl-buttons .owl-prev,
		.owl-controls .owl-buttons .owl-next {
			left: -45px;
			top: 37%;
			background-color: @ev-blue;
			color: @white;
			filter: Alpha(Opacity=100);
			opacity: 1;
			border-radius: 0;
			font-weight: @bold-font-weight;
			padding: 8px 10px 9px;
			height:32px;

			@media (min-width: @screen-xs-min) {
				padding: 8px 12px;
				top: 37%;
			}
			 i {
				margin-top: -4px;
			 }
		}

		.owl-controls .owl-buttons .owl-next {
			right: -45px;
			left: auto;
		}

		.popular-phone {
			width: 180px;
			text-align: center;
			margin: 0 auto;

			.pp-img {
				margin-bottom: 10px;
				height: 185px;
				position: relative;

				img {
					max-width: 100%;
					max-height: 100%;
					margin: 0 auto;
					position: absolute;
					right: 0;
					left: 0;
					bottom: 0;
				}
			}
		}
	}
}

#home-faqs {
	background-color: @grey;
	padding: 15px 0;

	#faq-list {
		.faq-head {
			cursor: pointer;
		}

		.panel {
			border: 0;
			box-shadow: none;
			margin-bottom: 8px;
		}

		a {
			&.collapsed {
				.open-icon {
					background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAOCAMAAAD32Kf8AAAAJFBMVEURten///8RtekRtekRtekRtekRtekRtekRtekRtekRtekRtekkvmCyAAAAC3RSTlMAABEzRFVmd5m7zEpdDCIAAABQSURBVHgBTcpLDoAwDMTQpOVTyP3vixfFijcjPU3QEa2cI5NdtZpe9Y4EqwoWC45ZJIN085V/5NtYzGgsovIjorKIyqIKbwxVBlUZVO0Et34+FAUM7/sLMgAAAABJRU5ErkJggg==) no-repeat center;
				}
			}
		}

		.open-icon {
			height: 14px;
			width: 21px;
			display: block;
			float: right;
			margin: 12px 13px 0 0;
			background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAOCAMAAAD32Kf8AAAAJFBMVEURten///8RtekRtekRtekRtekRtekRtekRtekRtekRtekRtekkvmCyAAAAC3RSTlMAABEzRFVmd5m7zEpdDCIAAABOSURBVHjaTc5JDsAwCARBbGfx8v//hlGkafrAoYQQQXfXbAqcZ3creJKtoNgKiq1Gc8EFgzMec8GAK8IxjPCbu6D43xULYd3Nrii1kT980SMFDEPQlWUAAAAASUVORK5CYII=) no-repeat center;
		}

		h4 {
			background-color: @white;
			padding: 10px 37px 10px 18px;
			color: @ev-blue;
			margin: 0;
		}
	}
}

#carousel, #complete-carousel {
	margin-bottom: 20px;

	@media (min-width: @screen-xs-min) {
		margin-bottom: 30px;
	}

	.item {
		background-color: @ev-blue;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		min-height: 251px;
		width: 100%;

		@media (min-width: @screen-md-min) {
			height: 360px;
			min-height: auto;
		}

		.float-img {
			padding: 20px 0 0;
			min-height: 250px;
			position: relative;
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;

			@media (min-width: @screen-md-min) {
				height: 360px;
				min-height: auto;
			}

			@media (min-width: @screen-lg-min) {
				background-size: contain;
			}
		}

		&.banner-right {
			.float-img {
				background-position: right;
				max-width: 1140px;
				margin: auto;
			}
		}

		.sub-headline {
			position: absolute;
			bottom: 10px;
			left: 10px;
			right: 10px;
			background-color: rgb(61, 57, 53);
			padding: 4px;
			.font-size(1.4);
			.line-height(1.6);

			@media (min-width: @screen-md-min) {
				.font-size(2);
				.line-height(2.6);
				left: auto;
				right: auto;
				bottom: 22px;
				padding: 12px 15px;
			}

			@media (min-width: @screen-lg-min) {
				width: 1120px;
			}
		}

		.headline {
			.font-size(2.2);
			.line-height(2.6);
			margin-top: 0;
			padding-top: 0;

			& span {
				padding: 0;
			}

			@media (min-width: @screen-md-min) {
				.font-size(4.2);
				.line-height(4.9);
			}
		}

		&.buy-and-sell {
			background-image: url('@{img-cdn}banners/banner_carousel_buyandsell_background.jpg');

			.float-img {
				background-image: url('@{img-cdn}banners/banner_carousel_buyandsell_foreground.png');
			}
		}

		&.envirocash {
			background-image: url('@{img-cdn2}tradein/website/pages/landingpage_envirocash/banner_envirocash_background.png');

			.float-img {
				background-image: url('@{img-cdn2}tradein/website/pages/landingpage_envirocash/banner_envirocash_foreground.png');
			}
		}

		&.inpost {
			background-image: url('@{img-cdn}banners/banner_inpost_background.png');

			.float-img {
				background-image: url('@{img-cdn}banners/banner_inpost_foreground.png');

				.headline {
					span {
						background: rgb(61, 57, 53);
						box-shadow: none;
						padding: 0px 15px;
					}
				}
			}
		}

		&.price-promise {
			background-image: url('@{img-cdn}banners/banner_carousel_navyblue_background.jpg');

			.float-img {
				background-image: url('@{img-cdn}banners/banner_carousel_pricepromise_foreground.png');
			}
		}

		&.same-day-pay {
			background-image: url('@{img-cdn}banners/banner_carousel_navyblue_background.jpg');

			.float-img {
				background-image: url('@{img-cdn}banners/banner_carousel_sameday_foreground.png');
			}
		}

		&.we-pay-more {
			background-image: url('@{img-cdn}banners/banner_carousel_navyblue_background.jpg');

			.float-img {
				background-image: url('@{img-cdn}banners/banner_carousel_wepaymore_foreground.png');
			}
		}
	}
}

#about {
	#find-more {
		a {
			margin-bottom: 15px;
			display: inline-block;
		}
	}

	.about-row {
		height: 320px;
		text-align: center;

		h4 {
			color: @ev-blue;
			font-weight: @headline-font-weight;
			.font-size(2.3);

			@media (min-width: @screen-sm) {
				.font-size(3.3);
			}
		}

		.icon {
			margin: 10px auto;
			width: 120px;
			height: 95px;
			background-size: 120px;

			@media (min-width: @screen-sm) {
				width: 170px;
				height: 140px;
				background-size: 170px;
			}

			@media (min-width: @screen-md) {
				margin: 20px auto;
			}

			&.happy-customers {
				background-image: url('@{img-cdn}icons/blue/icons_usp_happycustomers.svg');
			}

			&.damaged-phones {
				background-image: url('@{img-cdn}icons/blue/icons_usp_damagedphones.svg');
			}

			&.free-postage {
				background-image: url('@{img-cdn}icons/blue/icons_usp_freepostage.svg');
			}

			&.fast-payment {
				background-image: url('@{img-cdn}icons/blue/icons_usp_fastpayment.svg');
			}

			&.buying-from {
				background-image: url('@{img-cdn}icons/blue/15years.png');
				background-size: 110px;
				width: 110px;
				height: 110px;
				margin: 35px auto;
			}

			&.price-promise {
				background-image: url('@{img-cdn}icons/blue/icons_usp_pricepromise.svg');
			}

			&.environment {
				background-image: url('@{img-cdn}icons/blue/icons_usp_environment.svg');
			}

			&.ssl {
				background-image: url('@{img-cdn}icons/blue/icons_usp_ssl.svg');
			}

			&.packit {
				background-image: url('@{img-cdn}icons/blue/icons_usp_packit.svg');
			}
		}

		&.grey {
			background-color: @grey;
		}

		&.red {
			background-color: @ev-red;
			color: @white;
		}

		&.blue {
			background-color: @ev-blue;
			color: @white;
		}

		&.darkblue {
			background-color: @carousel-color;
			color: @white;
		}
	}
}

#btns-sell {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
	margin-bottom:20px;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
} 

#btns-sell a {
    display: block;
    margin: 10px 20px;
    padding: 10px 10px;
    border: 1px solid #e31b23;
    color: black;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
	transition: all .2s ease-in-out;
}

#btns-sell a:hover {
  background: #e31b23;
  color: white;
}

@media(max-width: 480px) {
  #btns-sell a {
   	margin: 10px 5px;
    font-size: 12px;
  }
}

@media(max-width: 360px) {
  #btns-sell a {
   	margin: 5px 2px;
    font-size: 11px;
    padding: 5px;
  }
}

#search-all-phones {
  margin-top: 25px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 700;
}

@media(max-width:1199px ) {
  #search-all-phones {
 	margin-bottom: 60px;
    margin-top: 25px;
  }
}

@media(max-width: 992px ) {
  #search-all-phones {
    margin-bottom: 35px;
    margin-top: 21px;
  }
}

@media(max-width: 768px ) {
  #search-all-phones {
    margin-bottom: 45px;
    margin-top: 25px;
  }
}

@media(max-width: 480px ) {
  #search-all-phones {
    margin-bottom: 25px;
    margin-top: 25px;
  }
}

#search-all-phones span {
	display: block;
	margin-bottom: 20px;
}

#search-all-phones a { 
	font-size: 20px;
}

.home-usps {
	background-color: @grey;
}


.sell-tech {
	background-color: #053149;
	margin-top: 10px;
	text-align: center;

	@media screen and (max-width: 1199px) {
		margin-bottom: 30px;
	}

	h2 {
		color: white;
		font-weight: @h1-font-weight;
		text-transform: none;
		text-align: left;
		.font-size(2.4);
		width: 100%;
		border-bottom: 1px solid;
		padding-bottom: 20px;
		margin-bottom: 30px;
		margin-top: 20px;

		@media screen and (max-width: 1199px) {
			margin-top: 40px;
		}

		@media screen and (max-width: 479px) {
			margin-top: 30px;
		}
	}

	.tech-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;

		.single-row-large {
			@media screen and (min-width: 1200px) {
				width: 14.2%;
				padding-left: 6px;
				padding-right: 6px;
			}

			.tech-item {
				background-color: white;
				padding: 20px;
				border: 1px solid @light-grey-border;
				border-radius: 8px;
				margin-bottom: 30px;

				@media screen and (max-width: 479px) {
					margin-bottom: 15px;
				}

				h3 {
					text-transform: none;
					.font-size(1.8);
					margin-bottom: 0;
				}

				img {
					max-width: 100%;
					height: 100px;
					object-fit: contain;
				}
			}
		}
	}
}