﻿.new-index {
    .btn, .btn:visited, .btn:active, .btn:link {
        display: inline;
        .font-size(1.2);
        font-weight: @bold-font-weight;
        text-transform: uppercase;
        padding: 13px 15px 13px 15px;
        background-color: @ev-red;
        color: @white;
        border: 1px solid @ev-red;

        &.blue {
            background-color: @ev-blue;
            border: 1px solid @ev-blue;
        }
    }

    .white, .white:visited, .white:active, .white:link {
        color: white;
        font-weight: @bold-font-weight;
    }

    #index-header {
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.5);

        .header-wrap {
            z-index: 20;
        }

        top: 0;
        border-bottom: 0;
        height: 80px;
        position: fixed;
        background-color: @white;
        width: 100%;

        .ef-logo {
            float: left;
            display: block;
            height: 40px;
            margin: 20px 0 20px 0;

            @media screen and (max-width: 767px) {
                margin-left: 30px;
            }

            @media screen and (max-width: 479px) {
                height: 30px;
                margin-left: 15px;
            }
        }

        .trustpilot {
            position: relative;
            top: -49px;
            z-index: -1;
            left: calc(100% - 115px);
            width: 100px;

            @media screen and (min-width: 373px) {
                left: calc(100% - 207px);
                width: 200px;
            }

            @media screen and (min-width: 480px) {
                left: calc(100% - 222px);
                width: 200px;
            }

            @media screen and (min-width: 532px) {
                left: ~"calc(100% - 362px)";
                width: 400px;
            }

            @media screen and (min-width: 768px) {
                left: ~"calc(50% - 150px)";
                width: 200px;
            }

            @media screen and (min-width: 992px) {
                left: ~"calc(50% - 200px)";
                width: 400px;
            }

            @media screen and (max-width: 479px) {
                top: -44px;
            }
        }

        .links {
            float: right;
            margin-top: -75px;
            min-width: 315px;
            margin-right: -15px;

            a:last-child {
                margin-left: 15px;
            }

            @media screen and (max-width: 767px) {
                p {
                    .font-size(2.0);
                    margin: 0 0 31px 0;
                }

                margin-right: 0;
                margin-top: -24px;
                padding: 30px 30px 45px 30px;
                text-align: left;
                width: 100%;
                background-color: @grey;
                box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.5);
            }

            @media screen and (max-width: 479px) {
                padding: 15px 15px 30px 15px;
            }
        }

        z-index: 19;
    }

    .index-section {
        .container {
            @media screen and (max-width: 479px) {
                padding: 0;
            }
        }

        .row {
            width: 100%;

            @media screen and (max-width: 767px) {
                margin-left: 0;
            }
        }

        &.buy-sell {
            margin-top: 227px;
            padding-top: 24px;
            background-image: linear-gradient(25deg, rgba(18, 180, 233, 0) 50%, rgba(18, 180, 233, 0.7) 90%), linear-gradient(205deg, rgba(199, 24, 30, 0) 50%, rgba(199, 24, 30, 0.9) 80%), url('https://tradein-assets.envirofone.com/pages/index/background-rotated.png');
            background-position: 0, 0, center;
            background-size: cover;
            color: @white;
            text-align: left;

            h1 {
                color: @white;
                margin-bottom: 20px;
                .font-size(3.4);

                @media screen and (max-width: 1199px) {
                    .font-size(2.8);
                    margin-top: 0;
                }
            }

            .phone {
                height: 300px;
                width: 300px;
                margin-top: 60px;
                margin-left: 40px;
                background-image: url('@{img-cdn}pages/index/phone.png');
                background-position: top center;
                background-size: 100%;

                @media screen and (max-width: 1199px) {
                    width: 240px;
                    margin-left: 36px;
                }
            }

            .intro {
                .line-height(2.4);
                font-weight: @light-font-weight;

                @media screen and (max-width: 1199px) {
                    .line-height(2.0);
                }
            }

            .col-sm-5-half {
                @media screen and (max-width: 991px) and (min-width: 768px) {
                    width: 45.833333335%;
                    float: left;
                }
            }

            .divider {
                background-color: #fff;
                height: 1px;
                width: 100%;
                margin-bottom: 20px;
                opacity: 50%;

                @media screen and (min-width: 768px) {
                    display: none;
                }
            }

            .bottom {
                top: 240px;
                width: 100%;
                padding-bottom: 20px;

                .col {
                    margin-top: 20px;
                    padding-left: 40px;
                    padding-right: 0;
                }

                .col::before {
                    left: 15px;
                    content: "";
                    background-image: url('@{img-cdn}pages/index/tick.svg');
                    width: 1.9rem;
                    height: 1.9rem;
                    background-size: 1.9rem;
                    position: absolute;
                    vertical-align: middle;

                    @media screen and (max-width: 767px) {
                        left: 0;
                    }
                }

                .col-cu-6 {
                    position: relative;

                    @media screen and (min-width: 480px) {
                        width: 50%;
                        float: left;
                    }

                    @media screen and (max-width: 767px) {
                        padding-left: 25px;

                        &:first-child {
                            padding-right: 25px;
                        }
                    }
                }

                .search {
                    background-color: @white;
                    border-radius: 8px;
                    width: 100%;
                    color: @black;
                    padding: 20px;
                    margin-top: 53px;
                    .font-size(1.2);

                    @media screen and (max-width: 479px) {
                        margin-top: 38px;
                    }
                }

                .btn-sm {
                    @media screen and (max-width: 479px) {
                        padding: 0;
                        color: @ev-red;
                        background-color: @white;
                        border: 0;

                        &.blue {
                            color: @ev-blue;
                        }
                    }

                    span {
                        @media screen and (max-width: 349px) {
                            display: none !important;
                        }
                    }
                }

                img {
                    max-height: 30px;
                    max-width: 100px;
                    margin: 10px 29px 10px 0;

                    @media screen and (max-width: 1199px) {
                        margin: 10px 25px 10px 0;
                    }

                    @media screen and (max-width: 991px) {
                        margin: 10px 34px 10px 0;
                    }

                    @media screen and (max-width: 479px) {
                        margin: 10px 16px 10px 0;
                    }
                }

                .interested {
                    margin-top: 45px;

                    @media screen and (max-width: 479px) {
                        margin-bottom: 0;
                    }
                }

                @media screen and (max-width: 767px) {
                    margin-top: 40px;
                }

                @media screen and (max-width: 479px) {
                    margin-top: 25px;
                }

                @media screen and (min-width: 768px) {
                    position: absolute;
                }
            }

            @media screen and (max-width: 479px) {
                padding-top: 15px;
                margin-top: 185px;
            }

            @media screen and (min-width: 768px) {
                margin-top: 80px;
                height: 386px;
                background-image: linear-gradient(70.14deg, rgba(199, 24, 30, 0) 50%, rgba(199, 24, 30, 0.7) 100%), linear-gradient(225.81deg, rgba(18, 180, 233, 0) 50%, rgba(18, 180, 233, 0.9) 100%), url('@{img-cdn}pages/index/background.png');
            }
        }

        &.buy-tech {
            .single-row-large {
                @media screen and (min-width: 1200px) {
                    width: 14.2%;
                    padding-left: 6px;
                    padding-right: 6px;
                }
            }
        }

        &.popular {
            text-align: left;
            margin: 0 15px 30px 15px;

            h2 {
                font-weight: @h1-font-weight;
                text-transform: none;
                text-align: left;
                .font-size(2.4);
                width: 100%;
                border-bottom: 1px solid;
                padding-bottom: 20px;
                margin-bottom: 30px;
                margin-top: 20px;

                @media screen and (max-width: 1199px) {
                    margin-top: 30px;
                }

                @media screen and (max-width: 479px) {
                    margin-top: 20px;
                }
            }

            .owl-prev, .owl-next {
                background-color: @ev-red !important;
            }

            .popular-phone {
                border: 1px solid @light-grey-border;
                border-radius: 8px;
                padding: 20px;
                width: 252px !important;

                p {
                    text-transform: none;
                    color: @black;
                    .font-size(1.3);

                    &.title {
                        font-weight: @medium-font-weight;
                        .font-size(1.6);
                        .line-height(1.8);
                        height: 30px;
                    }

                    span {
                        font-weight: @bold-font-weight;
                        .font-size(2.0);
                        .line-height(2.2);
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .btn {
                    display: block;
                }
            }
        }

        &.buy-tech {
            margin-top: 10px;

            @media screen and (max-width: 1199px) {
                margin-bottom: 30px;
            }

            @media screen and (max-width: 479px) {
                margin: 0 15px 20px 15px;
            }

            h2 {
                font-weight: @h1-font-weight;
                text-transform: none;
                text-align: left;
                .font-size(2.4);
                width: 100%;
                border-bottom: 1px solid;
                padding-bottom: 20px;
                margin-bottom: 30px;
                margin-top: 20px;

                @media screen and (max-width: 1199px) {
                    margin-top: 40px;
                }

                @media screen and (max-width: 479px) {
                    margin-top: 30px;
                }
            }

            .row {
                margin: 0;
            }

            .tech-item {
                padding: 20px;
                border: 1px solid @light-grey-border;
                border-radius: 8px;
                margin-bottom: 30px;

                @media screen and (max-width: 479px) {
                    margin-bottom: 15px;
                }

                h3 {
                    text-transform: none;
                    .font-size(1.8);
                    margin-bottom: 0;
                }

                img {
                    max-width: 100%;
                    height: 100px;
                    object-fit: contain;
                }
            }
        }
    }

    #footer {
        .footer-links {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            h4 {
                @media screen and (max-width: 991px) {
                    text-align: center;
                }
            }

            li {
                display: block;
                @media screen and (max-width: 991px) {
                    text-align: center;
                }

                a {
                    border: hidden;
                    text-transform: capitalize;
                }
            }

            .footer-logo {
                @media screen and (max-width: 991px) {
                    text-align: center;
                }
            }
        }
    }
}
