﻿.receive-ammount {
	text-transform: uppercase;
	text-align: center;
	color: @ev-blue;
	padding: 20px;
	.font-size(2.2);
	.line-height(2.2);
	margin-bottom: 20px;

	@media (min-width: @screen-sm) {
		border: 1px solid @ev-blue;
	}
}

.login-panel {
	&#new-cust-login {
		margin-bottom: 30px;
		border-bottom: 8px solid @grey;
	}

	@media (min-width: @screen-sm) {
		background-color: @grey;
		padding: 30px;
	}

	h2 {
		margin: 0 0 20px 0;
		font-weight: @bold-font-weight;
	}
}

.form-step-head {
	position: relative;
	left: -38px;
	top: 42px;

	@media (min-width: @screen-xs) {
		left: -61px;
		top: 48px;
	}

	.form-step-number {
		height: 24px;
		width: 24px;
		text-align: center;
		display: inline-block;
		border-radius: 50%;
		background-color: @ev-blue;
		color: @white;
		font-weight: @bold-font-weight;
		margin: 2px 8px 0 0;
		.font-size(1.4);
		.line-height(2.4);

		@media (min-width: @screen-xs) {
			width: 34px;
			height: 34px;
			.font-size(1.4);
			.line-height(3.4);
			margin: 16px 18px 0 8px
		}
	}
}

.form-step-body {
	margin: -20px 0 0 40px;

	@media (min-width: @screen-xs) {
		margin: -20px 0 0 70px;
	}

	&.first {
		margin-top: -45px;

		@media (min-width: @screen-xs) {
			margin-top: -57px;
		}
	}

	.form-control {
		width: 95%;
		display: inline-block;
	}

	.input-group {
		width: 95%;
		display: inline-table;

		.form-control {
			width: 100%;
		}
	}

	#PostCode {
		width: 47%;
		margin-bottom: 10px;
	}

	.btn {
		vertical-align: top;
	}

	.required {
		color: @ev-red;
		margin-left: 5px;
		vertical-align: top;
	}
}

#customers {
	margin-top: 34px;
}

#tablistContent.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -20px;
  border-bottom: 1px solid #ddd
}

#tablistContent .nav-item {
  flex: 0 0 50%;
}

#tablistContent .nav-item a{
  text-align: center;
  text-transform:uppercase;
  font-weight: bold;
  border-radius: 0;
  background-color: #f0f0f0;
  color: #282828;
  padding: 13px 0;
  position: relative;
  margin-right: 0;
}

#tablistContent .nav-item.active a{
  background-color: #FFF; 
}

#tablistContent .nav-item:not(.active) a{
  border-bottom: 1px solid #DDD;
}

#tablistContent .nav-item:not(:last-child) a{
  margin-right: 6px;
}

#tablist{
  padding-top: 30px;
}

#tablist #new-cust-login.login-panel {
  border-bottom: none;
}

@media only screen and (min-width: 767px){
  #tablistContent.nav {
     margin: 0;
  }

  #tablist {
    padding-top: 0;
    border: 1px solid #DDD;
    border-top: none;
    margin: 0;
  }

  #tablist .login-panel {
    background-color: #FFF;

  }

  #tablist .tab-pane {
    float: none;
    margin-left: auto;
    margin-right: auto; 
  }
}

#new-cust-login .form-group {
  display: flex;
  flex-direction: row;
}

#new-cust-login .form-group p {
  margin-left: 20px;
}

.trust-container h3 {
  margin-bottom: 30px;
}