﻿.font-size(@sizeValue) {
  @remValue: @sizeValue;
  @pxValue: (@sizeValue * 10);
  font-size: ~"@{pxValue}px"; 
  font-size: ~"@{remValue}rem";
}

.line-height(@sizeValue) {
  @remValue: @sizeValue;
  @pxValue: (@sizeValue * 10);
  line-height: ~"@{pxValue}px"; 
  line-height: ~"@{remValue}rem";
}