﻿.mob-checkout-nav {
	background-color: @grey;
	padding: 7px;
	font-weight: @bold-font-weight;
	margin: 20px 0;
}

.checkout-step-number {
	height: 24px;
	width: 24px;
	text-align: center;
	display: inline-block;
	border-radius: 50%;
	background-color: @ev-blue;
	color: @white;
	.font-size(1.4);
	.line-height(2.4);
	font-weight: @bold-font-weight;
	margin: 2px 8px 0 0;

	@media (min-width: @screen-xs-min) {
		width: 34px;
		height: 34px;
		.line-height(3.4);
		.font-size(1.4);
		margin: 0 8px 0 0;
	}
}

.mob-checkout-step {
	text-transform: uppercase;
}

.step {
	display: inline-block;
	height: 12px;
	width: 12px;
	border-radius: 50%;
	margin-right: 1px;

	&.done {
		background-color: #55b947 !important;
	}

	&.active {
		background-color: @ev-blue;
	}

	&.disabled {
		background-color: #afafaf;
	}
}

.mob-checkout-stepcount {
	display: inline-block;
	color: @ev-blue;
	.font-size(1.4);
}


.checkout-steps-container {

	@media (min-width: @screen-sm) {
		margin: 25px 0 30px 0;
	}
}

.basket-listing {

	@media (min-width: @screen-xs-min) {
		margin-top: 30px;
	}

	.product-thumbnail {
		width: 66px;
		height: 66px;
		border: 1px solid #808082;
		float: left;
		margin-right: 10px;
		padding: 4px;
	}

	margin-bottom: 20px;

	.row-item {
		padding: 10px 0;
	}

	.row-spacer {
		border-bottom: 1px solid @grey;
		margin-bottom: 10px;
	}

	.xs-row {
		padding-bottom: 10px;

		@media (min-width: @screen-sm-min) {
			padding-bottom: 0;
		}
	}

	.xs-to-md-right {
		text-align: right;

		@media (min-width: @screen-lg-min) {
			text-align: left;
		}
	}

	.column-heading {
		display: block;
		margin-bottom: 4px;
		font-weight: @bold-font-weight;
	}

	.line-total-row {
		padding-top: 6px;
		padding-bottom: 6px;

		@media (min-width: @screen-xs-min) {
			padding-top: 0;
			padding-bottom: 0;
			margin-top: 0;
			margin-bottom: 10px;
		}
	}

	.sub-total-row {
		border-bottom: 0;
		margin-top: 10px;
	}

	.total-row {
		border-bottom: 0;
		margin-top: 10px;
	}

	.total {
		border-top: 1px solid #808082;
		border-bottom: 1px solid #808082;
		padding: 10px 0;
		text-align: right;
		font-weight: bold;
	}

	.total-heading {
		padding: 10px 0;
	}

	.pd-container {
		margin-left: 78px;
		min-width: 186px;
	}
}


ul.checkout-steps {
	margin: 0;
	padding: 0;

	li {
		display: inline-block;
		text-align: left;
		width: 200px;
		margin-right: 18px;

		a {
			background-color: @grey;
			color: @black;
			display: block;
			height: 51px;
			.line-height(5.1);
			margin-right: 1px;
			padding: 0 10px;
			min-width: 138px;
			text-transform: uppercase;
			.font-size(1.2);
			font-weight: @bold-font-weight;
			text-decoration: none;
		}

		&.active a {
			color: @black;
		}

		&.active .checkout-step-number {
			background-color: @ev-blue;
			color: @white;
		}

		&.done {
			a {
				color: @black;
			}

			span.checkout-step-number {
				background-color: #55b947;
				background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAMAAAAVBLyFAAAAGFBMVEUAAAD///////////////////////////8jfp1fAAAAB3RSTlMAAYiLjpGXyGx4ogAAAD5JREFUeNpty1sKACAQQtGcSve/4yCG7OXX5YDlHEhcRKmeEpL4Cr4CPELienGGJbNZlsUSm2WznmKLTNsuA/EBAeHm2IuzAAAAAElFTkSuQmCC);
				background-repeat: no-repeat;
				background-position: center center;
				text-indent: -9999px;
			}

			&:hover, &:focus {

				a {
					color: @white;
					background-color: @ev-blue;
				}

				span.checkout-step-number {
					background-color: @white;
					background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAMAAAAVBLyFAAAAG1BMVEURter///8RteoRteoRteoRteoRteoRteoRtep4ybwBAAAACHRSTlMAAAGIi46RlyrvwFsAAABLSURBVHjaZc1RDsAgCAPQCXP0/ice2iYE5au8pPD0sQg7KIC3iwOIW+yWkRety9CPaiXFCiVJO86SJC4uIdEkItmXIpI5YRONQvoBKDsCVsp0rRUAAAAASUVORK5CYII=);
				}
			}
		}

		&.disabled:not(.done) {
			a {
				color: #afadae;
				cursor: not-allowed;
			}

			span.checkout-step-number {
				background-color: #afadae;
			}
		}
	}
}

#checkout {
	.charity-container-top {
		border-top: 1px solid #282828;
		margin-top: 20px;
		padding-top: 20px
	}

	.charity-container-bottom {
		border-bottom: 1px solid #282828;
		margin-bottom: 20px;
		padding-bottom: 20px
	}

	#charity-thank-you-logo {
		max-height: 150px;
		max-width: 100%;
		margin-top: 20px;
	}

	#envirocash,
	#bacs,
	#inpost,
	#dpd,
	#royalmailselfserve,
	#royalmail {
		cursor: pointer;
	}

	#charity-thank-you-logo-wrapper {
		text-align: center;
	}

	.head-value {
		color: @ev-blue;
	}

	.header {
		border-bottom: 1px solid @black;
		margin-bottom: 20px;

		@media (min-width: @screen-md) {
			height: 105px;
		}

		@media (min-width: @screen-lg) {
			height: 90px;
		}
	}

	.checkout-panel {
		@media (min-width: @screen-md) {
			margin-bottom: 0;
			background-color: @grey;
			padding: 1px 20px 20px 20px;
		}
	}

	.basket-item {
		.font-size(1.5);
		margin-bottom: 10px;

		.basket-item-col {
			padding-left: 5px;
			padding-right: 5px;
		}

		.plus-minus {
			.btn {
				display: block;
				padding: 0 5px;
				margin-bottom: 2px;
			}

			.input-group-btn:first-child > .btn {
				margin-left: 0;
			}

			input {
				width: 30px;
				margin: 10px 0 0 5px;
				text-align: center;
			}
		}

		.delete-button {
			background: transparent;
			border: 0;
			margin: 10px 0 0 0;
		}

		.form-control {
			width: 55px;
			padding: 6px 6px;
		}
	}

	#voucher-details {
		display: inline-block;
		margin: 20px 0 20px 0;
		.font-size(1.5);
	}

	#add-another-phone-link {
		border-top: 1px solid @black;
		border-bottom: 1px solid @black;
		padding-bottom: 10px;

		h3 {
			color: @ev-blue;
		}

		#add-another-phone {
			margin: 25px 0 20px 0;

			input {
				float: left;
				width: 80%;
				.font-size(1.2);
				border-color: @ev-red;
			}

			#search-btn {
				float: left;
				height: 40px;
				width: 20%;
				background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAgdJREFUeNqsls8rRFEUx98b40eNsFF+jImwZYGksKDMejZKFrNBiq3xB7CjaBZSJCULGzsbpCQ/sjI0hQWzMaGI/By/nu/Jw3HNfb+aU5/p3fvOud+599x77lM1TVOSmAv4QTuoBYUgF1yAfbAE5sGNYtVISKAJHGjmdgtCIC3JGP8QOwbAm2bPVkGOHaGQMMAdGAX1wAPcoBz0gmPBdwdkWRFqFGayBYoMAkl0CHywmDEzIRVEWMA2yLSy7mCQxb2CSiMhP3N+BF6LIt+ssvgZI6Ep5hi2KUI0sPgb2S6knyhzbHYgREt/xsaoSuZHB7OUHas9xb5pQpxPVgHcrH2vODMelykTirO216FQMXuOy4RirN3iQIRqYB1rn8qEVli7x4FQEGToz1Rwz2VF1QcSbNcEbew4OnOXLLbPrASFmfMDaLUgkg92WdwJyJBcOz9CVH2PhHIyDLIlIgEQEwrr+vdhTWYqe1EB1kAJe3+n5zAKXvRd2QbKJPlaAJ2qqr6bXXwFYNPGXXQNFoW+eZqZlRvWBTrAoYEA1bQRkKeXoHHh/ZwopkqT92XVoEY/kB5wBSJgAzzxcUAY9LO+WdD1s4wOiujvlv27MvSnJ4SZ0bXhSqkQE5sUxKapP6VCTGyaCS3T94RbSbEhJzR4t543ym0Afc9mm8FsUKPvRcpNOnwS1P4UYACFBzwZYTciJgAAAABJRU5ErkJggg==);
				background-repeat: no-repeat;
				background-position: center center;

				span {
					display: none;
				}
			}
		}
	}

	#add-voucher-Code {
		display: inline-block;
		margin: 20px 0 20px 0;
		width: 100%;

		input {
			float: left;
			width: 80%;
			.font-size(1.2);
		}

		#voucher-btn {
			float: left;
			height: 40px;
			width: 20%;
		}
	}

	#receive {
		border-top: 1px solid @black;

		h2 {
			color: @ev-blue;
		}

		span.receive-Promo-Text {
			color: @ev-blue;
		}
	}

	.email-contact {
		border-bottom: 1px solid @black;
		padding-bottom: 20px;
		margin-bottom: 20px;
	}

	#Finish, .continue {
		vertical-align: middle;
		margin-top: 15px;
		width: 100%;
		height: 60px;
		text-transform: uppercase;
		font-weight: normal;
		.font-size(2);
		padding: 15px;
	}

	.address-confirm {
		position: relative;

		#edit-address {
			position: absolute;
			top: 29px;
			right: 0px;
		}

		.address {
			display:inline-block;
		}

		.btn {
			float: right;
		}
	}

	#payment-options {
		.payment-option-head {
			margin-bottom: 15px;
			background-color: @white;
			border: 1px solid @light-grey;
			padding: 15px 10px;

			h3 {
				margin: 0;
				display: inline-block;
			}

			input {
				margin: 0;
				float: right;
				display: inline-block;
				width: 20px;
				height: 20px;
			}

			.enviro-cash {
				font-size: 10px;
				color: #ff0000;
				padding: 0 0 0 10px;
				font-weight: 500;
				position: relative;
				top: -2px;
			}
		}

		.sort-code {
			width: 50px;
			display: inline-block;
		}
	}

	.delivery-option-head {
		margin-bottom: 15px;
		background-color: @white;
		border: 1px solid @light-grey;
		padding: 15px 10px;

		img {
			height: 40px;
			margin: 0px 8px;
		}

		h3 {
			margin: 0;
			display: inline-block;
			text-transform: inherit;
			padding: 0px 0px 7px;
		}

		input {
			margin: 0;
			float: right;
			display: inline-block;
			width: 20px;
			height: 20px;
		}

		p {
			font-size: 1.3rem;
		}
	}

	.sort-code {
		width: 50px;
		display: inline-block;
	}

	.anchor {
		display: inline-block;
		height: 100px;
		margin-top: -100px;
	}
}

#complete {
	.sale-ref {
		color: @ev-blue;
	}

	#what-happens {
		margin: 20px 0;

		p {
			margin: 20px auto;

			@media (min-width: @screen-sm) {
				width: 70%;
			}
		}

		.inpost-link {
			display: block;
			margin-top: 20px;

			div {
				background-color: #feca19;
				display: inline-block;
				width: auto;
				padding: 10px;
				align-items: center;
			}

			p {
				color: black;
				display: inline-block;
				width: auto;
				padding: 0 15px;
			}
		}
	}

	.usp-container {
		.icon {
			height: 120px;
			background-repeat: no-repeat;

			&.prep-it {
				background-image: url('@{img-cdn}icons/blue/icons_usp_prepit.svg');
			}

			&.pack-it {
				background-image: url('@{img-cdn}icons/blue/icons_usp_packit.svg');
			}

			&.bag-it {
				background-image: url('@{img-cdn}icons/blue/icons_usp_bagit.svg');
			}

			&.post-it {
				background-image: url('@{img-cdn}icons/blue/icons_usp_postit.svg');
			}
		}
	}

	.rm-portal {
		color: white;
		background: red;

		img {
			margin: 5px 8px;
			height: 40px;
		}
	}
}

#complete-foot {
	h2 {
		text-align: center;
		margin: 30px auto 50px auto;

		@media (min-width: @screen-sm) {
			width: 70%;
		}
	}

	.btn {
		.font-size(1.8);
		text-transform: uppercase;
		font-weight: normal;
		width: 100%;
		margin-bottom: 15px;
		padding: 20px;
		color: @white;
		padding-left: 65px;
		background-size: 65px 65px;

		&.blog {
			background-image: url('@{img-cdn}banners/banner_socialpanel_blog.svg');
			background-repeat: no-repeat;
		}

		&.facebook {
			background-image: url('@{img-cdn}banners/banner_socialpanel_facebook.svg');
			background-color: @facebook;
			background-repeat: no-repeat;
		}

		&.twitter {
			background-image: url('@{img-cdn}banners/banner_socialpanel_twitter.svg');
			background-color: @twitter;
			background-repeat: no-repeat;
		}

		&.instagram {
			background-color: @instagram;
		}
	}
}

#basket {
	#checkout-but {
		margin-top: 20px;
		width: 100%;

		@media (min-width: @screen-sm) {
			width: auto;
		}
	}
}

.bacserror:not(:empty) {
	display: block;
	margin-bottom: 10px
}

.mt-10 {
	margin-top: 10px;
}

.mb-0 {
	margin-bottom: 0;
}