﻿#sell-my-mobile {
	.big-search {
		margin: 40px 0 30px 0;
	}

	#most-popular {
		margin-bottom: 40px;

		a {
			width: 133px;
			height: 30px;
			display: block;
			background-position: center;
			background-repeat: no-repeat;
			background-size: 105px;

			@media (min-width: @screen-sm) {
				width: 175px;
				height: 65px;
				background-size: 155px;
			}

			&.iphone {
				background-image: url('@{img-cdn}logos/logos_phones_iPhone.svg');
			}

			&.ipad {
				background-image: url('@{img-cdn}logos/logos_phones_iPad.svg');
			}

			&.htc {
				background-image: url('@{img-cdn}logos/logos_phones_htc.svg');
			}

			&.nokia {
				background-image: url('@{img-cdn}logos/logos_phones_nokia.svg');
			}

			&.blackberry {
				background-image: url('@{img-cdn}logos/logos_phones_blackberry.svg');
			}

			&.huawei {
				background-image: url('@{img-cdn}logos/logos_phones_huawei.svg');
			}

			&.lg {
				background-image: url('@{img-cdn}logos/logos_phones_lg.svg');
			}

			&.samsung {
				background-image: url('@{img-cdn}logos/logos_phones_samsung.svg');
			}

			&.sony {
				background-image: url('@{img-cdn}logos/logos_phones_sony.svg');
			}

			&.lenovo {
				background-image: url('@{img-cdn}logos/logos_phones_lenovo.svg');
			}
		}
	}

	#smm-manufacturers {
		background-color: @grey;
		padding: 30px 0 20px 0;
		margin-bottom: 30px;

		ul {
			padding-left: 20px;

			li {
				list-style-type: none;
			}
		}
	}

	#az-links {
		text-align: center;
		margin-bottom: 20px;

		a {
			width: 90px;
			height: 30px;
			margin: 0 10px;
			display: inline-block;
			padding-top: 5px;

			&:hover {
				background-color: @grey;
			}

			&.open {
				background-color: @grey;
				border-bottom: 1px solid @dark-grey;
			}
		}
	}
}
