﻿#faqs {
    border-top: 1px solid @black;
    margin-top: 20px;
    padding-top: 30px;
}

.faq {
    margin-bottom: 20px;
}

.faq-section {
    padding-top: 5px;
    position: relative;
    margin-bottom: 5px;

    @media (min-width: @screen-xs-min) {
        padding-top: 10px;
        margin-bottom: 10px;
    }

    .link {
        display: block;
        height: 45px;
        line-height: 45px;
        text-transform: uppercase;
        .font-size(1.8);
        padding-left: 55px;
        background: @grey url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAOCAMAAAD32Kf8AAAAJFBMVEURten///8RtekRtekRtekRtekRtekRtekRtekRtekRtekRtekkvmCyAAAAC3RSTlMAABEzRFVmd5m7zEpdDCIAAABQSURBVHgBTcpLDoAwDMTQpOVTyP3vixfFijcjPU3QEa2cI5NdtZpe9Y4EqwoWC45ZJIN085V/5NtYzGgsovIjorKIyqIKbwxVBlUZVO0Et34+FAUM7/sLMgAAAABJRU5ErkJggg==) no-repeat 15px center;
        color: @ev-blue;
		cursor: pointer;

        @media (min-width: @screen-xs-min) {
            background-size: 21px 14px;
            .font-size(2.2);
            height: 60px;
            line-height: 60px;
        }

        &.open {
            background-color: @carousel-color;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAOCAMAAAD32Kf8AAAAJFBMVEURten///8RtekRtekRtekRtekRtekRtekRtekRtekRtekRtekkvmCyAAAAC3RSTlMAABEzRFVmd5m7zEpdDCIAAABOSURBVHjaTc5JDsAwCARBbGfx8v//hlGkafrAoYQQQXfXbAqcZ3creJKtoNgKiq1Gc8EFgzMec8GAK8IxjPCbu6D43xULYd3Nrii1kT980SMFDEPQlWUAAAAASUVORK5CYII=);

        }
    }

    .faq-icon {
        position: absolute;
        top: 0;
        right: 14px;
    }
}

.faq-section-questions {
    padding-top: 10px;

    @media (min-width: @screen-xs-min) {
        padding-top: 20px;
    }
}

.faq-question {
    font-weight: @bold-font-weight;
    padding-left: 30px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAABlBMVEURten///8eA8c1AAAAGUlEQVR4AWOgFmBkHBhBRhSAT5D+7qQSAAAsTAA1A2aTWgAAAABJRU5ErkJggg==) no-repeat top left;
    background-size: 14px 14px;
    color: @black;
    display: block;
	cursor: pointer;

    @media (min-width: @screen-xs-min) {
        background-size: 20px 20px;
    }

    &.open {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAABlBMVEUFMUkRtenSd2ebAAAAEklEQVR4AWMY6oARBWAXHOIAABicAB0wgju+AAAAAElFTkSuQmCC);
    }
}

.faq-answer {
    padding: 8px 0 8px 30px;
}