﻿#carousel {
	&.envirocash {
		margin-bottom: 0;
	}
}

#ec-section-white {
	text-align: center;
	max-width: 810px;
	padding: 10px 15px 10px 15px;

	@media (min-width: @screen-md) {
		padding: 30px 0 20px 0;
	}

	h2, p {
		margin-bottom: 30px;
	}
}

#ec-section-blue {
	text-align: center;
	background-color: @ev-blue;
	color: @white;
	padding: 20px 0 40px 0;

	@media (min-width: @screen-sm) {
		padding: 30px 0 40px 0;
	}

	h3 {
		color: @white;
		margin-bottom: 30px;
		.font-size(1.8);
	}

	h2 {
		color: @white;
		margin-bottom: 10px;
	}

	.icon {
		margin: 30px auto;
		width: 150px;
		height: 150px;
		background-size: 150px;

		&.damaged-phones {
			background-image: url('@{img-cdn2}global/icons/svg/rendered/sellit.svg');
		}

		&.free-postage {
			background-image: url('@{img-cdn2}global/icons/svg/rendered/sendit.svg');
		}

		&.fast-payment {
			background-image: url('@{img-cdn2}global/icons/svg/rendered/spendit.svg');
		}
	}
}

#ec-section-red {
	text-align: center;
	background-color: @ev-red;
	color: @white;
	padding: 20px 0 10px 0;
	margin-bottom: 30px;

	@media (min-width: @screen-sm) {
		padding: 30px 0 40px 0;
	}

	h2, p {
		color: @white;
		margin-bottom: 30px;
	}

	#Searchform {
		margin: 0 auto 30px auto;

		@media (min-width: @screen-md) {
			width: 850px;
		}
	}

	#SearchText {
		width: 85%;
		height: 55px;
		color: @white;
		background-color: #EA545A;
		border: 2px solid @white;
		.font-size(1.5);
		float: left;

		@media (min-width: @screen-md) {
			width: 600px;
			.font-size(2.5);
			height: 70px;
		}

		&::placeholder { /* Chrome/Opera/Safari */
		  color: #F8C6C8;
		}
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: #F8C6C8;
		}
		&::-moz-placeholder { /* Firefox 19+ */
		  color: #F8C6C8;
		}
		&:-ms-input-placeholder { /* IE 10+ */
		  color: #F8C6C8;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: #F8C6C8;
		}
	}

	#search-btn {
		background-color: @white;
		color: @ev-red;
		float: left;
		height: 55px;
		width: 15%;
		border: 2px solid @white;
		.font-size(1.8);
		text-transform: uppercase;
		background-image: url('@{img-cdn2}global/icons/svg/simple/red/search.svg');
		background-repeat: no-repeat;
		background-position: center; 

		@media (min-width: @screen-sm) {
			background-image: none;
		}

		@media (min-width: @screen-md) {
			.font-size(2.2);
			width: 250px;
			height: 70px;
		}
	}
}
