﻿.btn {
	padding: 9px 12px;
    border-radius: 0;
}

.btn-ev, a.btn-ev { 
	background-color: @ev-blue;
	border-color: @ev-blue;
	color: @white;
	font-weight: @bold-font-weight;

	&:hover, &:focus, &:active {
		color: @white;
	}
}

.btn-ev-white, a.btn-ev-white {
	background-color: @white;
	border-color: @white;
	color: @ev-blue;
	font-weight: @bold-font-weight;

	&:hover, &:focus, &:active {
		color: @ev-blue;
	}
}

.btn-ev-red, a.btn-ev-red {
	background-color: @ev-red;
	border-color: @ev-red;
	color: @white;
	font-weight: @bold-font-weight;

	&:hover, &:focus, &:active {
		color: @white;
	}
}

.btn-green-tick, a.btn-green-tick {
	color: #54b948;
	border: 1px solid #54b948;
	font-weight: @bold-font-weight;
	padding-left: 0;
	background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAQCAMAAADZJlTeAAAAKlBMVEVUuUj///9UuUhUuUhUuUhUuUhUuUhUuUhUuUhUuUhUuUhUuUhUuUhUuUhalyfJAAAADXRSTlMAABAwUGB/j5+vv8/viLntbgAAAFxJREFUeAF1ykEOhDAMxdDM0JQC/fe/LgtLCJrE22db++/t9yrwJXnlsNQqh3UUDmtuH/fZc8Zd0sgYbxJDYNzFEBm3ISAw/gywRWcQnDkDHJ0BrtwGXLr1E179BvmDByjFuAzRAAAAAElFTkSuQmCC) no-repeat right center;

	&:hover, &:focus, &:active {
		color: #54b948;
	}
}

.btn-facebook, .btn-facebook:hover, .btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active, .btn-facebook:visited {
	background-color: @facebook;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAyCAMAAADleEJoAAAAIVBMVEX///////////////////////////////////////////9/gMdvAAAACnRSTlMAESJEZneZu93uLDRhcQAAAFFJREFUeNrt0zEOgDAMQ1FaJ6T99z8wAwxMqVBH4vkp9pJjFUqUeKWZe8+ETcAT0WAhDKYpa/H7wIaQTjiVtPBEGyJiwIhYL/2L6FKvjyrxQVwCJATEgbcgHwAAAABJRU5ErkJggg==);
	background-repeat: no-repeat;
	background-position: left center;
	padding-left: 43px;
	border-color: @facebook;
	color: @white;
	font-weight: @bold-font-weight;
}

.btn-grey, a.btn-grey {
	background-color: @grey;
	border-color: @black;
	color: @black;
	font-weight: @bold-font-weight;

	&:hover, &:focus, &:active {
		color: @black;
	}
}

.add-to-basket, .added-to-basket {
	width: 100%;
	padding: 12px;
	margin-top: 10px;

	@media (min-width: @screen-xs-min) {
		width: 186px;
		margin-top: 0;
	}
}

.added-to-basket {
	margin-bottom: 20px;

	@media (min-width: @screen-xs-min) {
		padding-left: 0;
		padding-right: 24px;
		margin-bottom: 9px;
	}
}

.btn-remove {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAMAAABhEH5lAAAAQlBMVEUAAABxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXFxcXEZxJ1BAAAAFXRSTlMAAgMKFRckMDpOUIqkpbq+0eTm8fbSktJCAAAAfUlEQVR42m2QSRLFIBBCMQ4xzlG5/1Xj97vTt6ToLgA/hIuVrNEJLO7CRbn/ytPZvNXa+sb+TE9nUpioxD58ojBdJkhABnMlFgHHpszLLGXma1SjQ6RHIJkzyQDPiEqLYZhkCcsKUmNoS4EmT9J+eHq/hzhE3Qvttc/j7BN+S9UN6WaQ8aQAAAAASUVORK5CYII=);
}

.btn-grow-mobile {
	width: 100%;
	max-width: 420px;
    margin-bottom: 10px;

	@media (min-width: @screen-xs-min) {
		width: auto;
		max-width: none;
	}
}

.form-control {
	height: 40px;
    border-radius: 0;
}

input.form-control.postcode {
	width: 140px;
	display: inline-block;

	@media (min-width: @screen-xs-min) {
		width: 200px;
	}
}


.alert-danger, validation-summary-errors {
	background-color: @white;
	border-color: @ev-red;
	color: @black;
}

.alert-warning, .alert-info {
	background-color: @white;
	border-color: @ev-blue;
	color: @black;
}

.field-validation-error, .text-danger {
	color: @ev-red;
}

/* iCheck plugin Square skin, blue
----------------------------------- */
.icheckbox_square-blue,
.iradio_square-blue {
	display: inline-block;
	*display: inline;
	vertical-align: middle;
	margin: 0;
	padding: 0;
	width: 22px;
	height: 22px;
	background: url("@{img-cdn}icons/blue.png") no-repeat;
	border: none;
	cursor: pointer;
}

.icheckbox_square-blue {
	background-position: 0 0;
	float: left
}

.icheckbox_square-blue.hover {
	background-position: -24px 0;
}

.icheckbox_square-blue.checked {
	background-position: -48px 0;
}

.icheckbox_square-blue.disabled {
	background-position: -72px 0;
	cursor: default;
}

.icheckbox_square-blue.checked.disabled {
	background-position: -96px 0;
}

.iradio_square-blue {
	background-position: -120px 0;
}

.iradio_square-blue.hover {
	background-position: -144px 0;
}

.iradio_square-blue.checked {
	background-position: -168px 0;
}

.iradio_square-blue.disabled {
	background-position: -192px 0;
	cursor: default;
}

.iradio_square-blue.checked.disabled {
	background-position: -216px 0;
}
/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
	.icheckbox_square-blue,
	.iradio_square-blue {
		background-image: url("@{img-cdn}icons/blue@2x.png");
		-webkit-background-size: 240px 24px;
		background-size: 240px 24px;
	}
}

.form-error {
	border-color: @ev-red;
}

.form-valid {
	border-color: #20D60A;;
}
