﻿#Sortform {
	select {
		max-width: 200px;
		display: inline-block;
	}
}

.pagination-container {
	text-align: left;
}

.pagination {
	margin-top: 10px 0 0 0;

	@media (min-width: @screen-sm) {
		margin-top: 0;
	}

	.first, .last, .disabled {
		display: none;
	}

	li {
		&.next, &.prev {
			a {
				color: @white;
				background-color: @ev-blue;
			}

			a:hover, span:hover {
				color: @white;
				background-color: @carousel-color;
			}
		}

		a, span, a:focus, span:focus {
			border: 0;
			font-weight: @bold-font-weight;
			color: @ev-blue;
			background-color: transparent;
		}

		a:hover, span:hover {
			color: @carousel-color;
			background-color: transparent;
		}
	}

	.active {
		a, span, a:hover, span:hover, a:focus, span:focus {
			background-color: transparent;
			color: @carousel-color;
			font-weight: @bold-font-weight;
			z-index: auto;
		}
	}
}

.search-result {
	text-align: center;
	margin: 0 0 20px 0;

	.master {
		cursor: default;
	}

	.device-image {
		height: 170px;
		position: relative;
	}

	img {
		margin: 0 auto 20px auto;
		max-height: 150px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.description {
		padding: 8px;
		text-transform: none;
		text-align: center;
		color: @black;
		background-color: @grey;
		border-bottom: 1px solid @white;
	}

	.sell-now {
		width: 100%;

		table {
			width: 100%;
			text-align: center;
			background-color: @ev-red;

			tr {
				td {
					border-right: 1px solid @white;

					a {
						display: block;
						padding: 8px;
						color: @white !important;
					}

					a:hover {
						background-color: @carousel-color;
					}

					&:last-child {
						border-right: none;
					}
				}

				&:nth-child(2), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
					border-top: 1px solid @white;
				}
			}
		}

		@media (min-width: @screen-xs) {
			.font-size(1.2);
		}

		@media (min-width: 580px) {
			.font-size(1.6);
		}
	}
}

#search-description {

	@media (min-width: @screen-sm) {
		margin: 0 10% 30px 10%;
	}
}

#no-results {
	
}
