﻿// Block Grid
// Technique adapted from Foundation 5 for Bootstrap 3.
// https://github.com/zurb/foundation/blob/f755d8704123f86c281ede0b171881e2672f150d/scss/foundation/components/_block-grid.scss

[class*="block-grid-"] {
  display: block;
  // margin: -(@grid-gutter-width/2);
  padding: 0;
  .clearfix();
}

.block-grid-item {
  display: inline;
  margin: 0;
  padding: (@grid-gutter-width/2);
  height: auto;
  float: left;
  list-style: none;
}

.block-grid (@per-row) {
  & > .block-grid-item {
    width: (100%/@per-row);

    @nth-equation: ~"@{per-row}n+1";
    &:nth-of-type(n) { clear: none; }
    &:nth-of-type(@{nth-equation}) { clear: both; }
  }
}

.block-grids(@size) {
  .block-grid-@{size}-1  { .block-grid(1);  }
  .block-grid-@{size}-2  { .block-grid(2);  }
  .block-grid-@{size}-3  { .block-grid(3);  }
  .block-grid-@{size}-4  { .block-grid(4);  }
  .block-grid-@{size}-5  { .block-grid(5);  }
  .block-grid-@{size}-6  { .block-grid(6);  }
  .block-grid-@{size}-7  { .block-grid(7);  }
  .block-grid-@{size}-8  { .block-grid(8);  }
  .block-grid-@{size}-9  { .block-grid(9);  }
  .block-grid-@{size}-10 { .block-grid(10); }
  .block-grid-@{size}-11 { .block-grid(11); }
  .block-grid-@{size}-12 { .block-grid(12); }
}

.block-grids(xs);
@media (min-width: @screen-xs-min) { .block-grids(sm) }
@media (min-width: @screen-md-min) { .block-grids(md) }
@media (min-width: @screen-lg-min) { .block-grids(lg) }