﻿#sales {
	#my-account-menu {
		margin: 30px 0;

		.btn {
			.font-size(1.6);
			text-transform: uppercase;
			font-weight: normal;
			width: 100%;
			margin-bottom: 15px;
			padding: 15px;
			background-color: @grey;
			white-space: normal;
		}
	}

	.heading-underline {
		padding-bottom: 5px;
	}

	.charity {
		color: @ev-red;
	}

	.customer {
		color: @ev-blue;
	}

	table {
		width: 100%;
		
		td:nth-child(2) {
			text-align: right;
		}

		td {
			padding-bottom: 10px;
		}

		tr:last-child {
			td {
				padding-bottom: 0;
			}
		}
	}

	.sales-panel {
		margin-bottom: 20px;
		background-color: @grey;
		padding: 1px 20px 20px 20px;

		@media (min-width: @screen-md) {
			margin-bottom: 0;
			min-height: 167px;
		}

		.prod-image {
			float: left;
			width: 60px;
		}

		.prod-desc {
			margin-left: 75px;
		}

		.help {
			width: 25px;
			height: 25px;
			text-align: center;
			display: inline-block;
			border-radius: 50%;
			background-color: @ev-blue;
			color: @white;
			margin: 9px 0;
			.line-height(2.4);

			@media (min-width: @screen-xs) {
				width: 25px;
				height: 25px;
				.line-height(2.4);
				margin: 14px 0;
			}
		}
	}

	.reject-text {
		margin-top: 20px;
	}

	.row {
		margin-bottom: 20px;
	}
}
