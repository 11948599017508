﻿#about {
	&.how-it-works {
		.about-row {
			height: auto;
			min-height: 320px;
			text-align: center;

			p {
				.line-height(2.9);
			}

			h3 {
				color: white;
			}

			h4 {
				margin: 20px 0;
				color: @white;

				span {
					display: inline-block;
					vertical-align: middle;
				}
			}

			ul {
				padding: 0;

				li {
					display: block;
					list-style-type: none;
					background-position: left center;
					background-repeat: no-repeat;
					text-transform: uppercase;
					height: 60px;
					line-height: 60px;
					padding-left: 65px;
					background-size: 60px;
					text-align: left;

					@media (min-width: @screen-sm) {
						display: inline-block;
						height: 80px;
						line-height: 80px;
						background-size: 80px;
						padding-left: 85px;
						text-align: center;
					}

					&.bank-transfer {
						background-image: url('@{img-cdn}icons/red/icons_usp_fastpayment.svg');
					}

					&.envirocash {
						background-image: url('@{img-cdn2}global/icons/svg/simple/red/envirocash.svg');
					}

					&.envirocharity {
						background-image: url('@{img-cdn2}tradein/website/pages/how-it-works/envirocharity-usp-icon.svg');
					}
				}
			}

			.about-bottom-padding {
				padding-bottom: 40px;
			}

			&.about-header {
				padding-top: 30px;
				text-align: left;

				@media (min-width: @screen-md) {
					padding-top: 65px;
				}

				@media (min-width: @screen-lg) {
					padding-top: 85px;
				}

				h1.headline {
					.font-size(2.5);
					margin-top: 0;

					@media (min-width: @screen-sm) {
						.font-size(3.5);
					}
				}
			}

			&.about-search {
				h4 {
					color: @carousel-color;
				}
			}

			.about-icon {
				width: 80px;
				height: 80px;
				background-size: 80px;
				margin-right: 10px;

				&.search {
					background-image: url('@{img-cdn2}tradein/website/pages/how-it-works/search-icon-darkblue.svg');
				}

				&.sell {
					background-image: url('@{img-cdn2}tradein/website/pages/how-it-works/sell-icon-greenring.svg');
				}

				&.send {
					background-image: url('@{img-cdn2}tradein/website/pages/how-it-works/envirowrap-icon.svg');
				}

				&.spend {
					background-image: url('@{img-cdn2}tradein/website/pages/how-it-works/payment-icon.svg');
				}
			}
		}

		.icon-small {
			margin: 5px;
			width: 50px;
			height: 50px;
			background-size: 50px;
			text-align: center;
			float: left;
			background-repeat: no-repeat;

			&.search {
				background-image: url('@{img-cdn2}tradein/website/pages/how-it-works/search-icon-white.svg');
			}

			&.sell {
				background-image: url('@{img-cdn2}tradein/website/pages/how-it-works/sell-icon.svg');
			}

			&.send {
				background-image: url('@{img-cdn2}tradein/website/pages/how-it-works/envirowrap-icon.svg');
			}

			&.spend {
				background-image: url('@{img-cdn2}tradein/website/pages/how-it-works/payment-icon.svg');
			}
		}

		.menu {
			margin-bottom: 30px;

			a {
				margin-top: 9px;
				display: block;
				margin: 25px 0 0 65px;
				color: @white;
			}
		}

		.why-text {
			padding: 20px 20px 0 20px;
			text-align: center;

			@media (min-width: @screen-sm) {
				text-align: left;

				&.right {
					text-align: right;
				}
			}

			@media (min-width: @screen-md) {
				padding-top: 80px;
			}
		}

		.why-img {
			text-align: center;

			img {
				margin: 20px 0 0 0;
				width: 100px;
			}

			@media (min-width: @screen-md) {
				img {
					margin: 20px 20px 0 0;
					width: 180px;
				}
			}
		}

		h2 {
			margin-top: 40px;
			color: @carousel-color;
			font-weight: @headline-font-weight;
		}
	}
}
