﻿.contact-form {
    background-color: @grey;
    padding: 10px 10px 20px 10px;
    margin-bottom: 20px;
}

.contact-faq-list {
    margin: 20px 0 0;
}

.cs-hours {
    margin: 20px 0;
}

.img-list {
    vertical-align: middle;
    display: table-row;    
    

    > div{
        display: table-cell;
        vertical-align: middle;
        padding-bottom: 10px;
        img{ padding-right: 8px;}
    }
}

.g4l{ margin: 10px auto; }

#xmas-hours {
	color: @ev-red;
}