﻿#product-detail {
	margin-top: 20px;
	margin-bottom: 20px;

	@media (min-width: @screen-sm) {
		margin-top: 55px;
		margin-bottom: 55px;
	}

	.btn-ev-blue {
		background: @ev-blue;
		color: #fff;
		font-weight: 700;
	}

	#grades {
		margin-left: 0px;
		margin-right: 0px;

		.grade {
			padding: 0;

			.grade-button {
				border-right: 1px solid white;
				width: 100%;
			}
		}

		.grade-details {
			padding: 0px;

			p {
				padding-top: 10px;
				margin-bottom: 0;
				font-size: 1.3rem;
			}

			ul {
				padding: 10px 0 0 17px;
				font-size: 1.3rem;
			}
		}
	}

	.product-img {
		text-align: center;
		padding: 0;

		@media (min-width: @screen-sm) {
			border: 1px solid @light-grey;
			height: 371px;
			padding: 30px;
		}

		@media (min-width: @screen-md) {
			height: 316px;
		}

		&.network {

			@media (min-width: @screen-sm) {
				height: 595px;
			}

			@media (min-width: @screen-md) {
				height: 316px;
			}
		}
	}

	.detail-container {
		padding-top: 10px;

		@media (min-width: @screen-sm) {
			border: 1px solid @ev-red;
			min-height: 316px;
			padding: 30px;
		}

		#network-logos {
			margin-bottom: 10px;

			.btn-network {
				width: 67px;
				height: 55px;
				border: 1px solid #f0f0f0;
				border-radius: 4px;
				margin: 5px;
				background-repeat: no-repeat;
				text-indent: -9999px;

				&.active {
					border-color: @ev-red;
					box-shadow: none;
				}
			}

			#network-o2 {
				background-image: url('@{img-cdn}logos/network/o2-logo.svg');
				background-position: 2px 5px;
			}

			#network-three {
				background-image: url('@{img-cdn}logos/network/3-logo.png');
				background-size: 38px;
				background-position: 13px 5px;
			}

			#network-ee {
				background-image: url('@{img-cdn}logos/network/ee-logo.svg');
				background-size: 25px;
				background-position: 19px 5px;
			}

			#network-vodafone {
				background-image: url('@{img-cdn}logos/network/vodafone-logo.png');
				background-size: 57px;
				background-position: 4px 5px;
			}

			#network-tesco-mobile {
				background-image: url('@{img-cdn}logos/network/tesco-mobile-logo.png');
				background-size: 66px;
				background-position: 0px 12px;
			}

			#network-giffgaff {
				background-image: url('@{img-cdn}logos/network/giffgaff-logo.png');
				background-size: 60px;
				background-position: 3px 13px;
			}

			#network-virgin {
				background-image: url('@{img-cdn}logos/network/virgin-logo.png');
				background-size: 68px;
				background-position: -1px 5px;
			}

			#network-sky-mobile {
				background-image: url('@{img-cdn}logos/network/sky-mobile-logo.png');
				background-size: 65px;
				background-position: 0px 15px;
			}

			#network-bt-mobile {
				background-image: url('@{img-cdn}logos/network/bt-logo.png');
				background-size: 53px;
				background-position: 5px 1px;
			}

			#network-other {
				background-image: url('@{img-cdn}logos/network/other-logo.png');
				background-size: 50px;
				background-position: 7px 1px;
			}

			#network-unlocked {
				background-image: url('@{img-cdn}logos/network/unlocked-logo.png');
				background-size: 50px;
				background-position: 7px 1px;
			}
		}

		.quantity-container {
			@media (min-width: @screen-md) {
				margin-top: 80px;
			}
		}

		.price {
			.font-size(4.5);
			line-height: 1;
			font-weight: @bold-font-weight;
			margin: 37px 0 15px 0;
			color: @ev-red;

			@media (min-width: @screen-sm) {
				margin: 40px 0 15px 0;
			}

			@media (min-width: @screen-md) {
				margin: 16px 0 15px 0;
			}

			.small {
				.font-size(3);
			}

			&.int {
				margin: 42px 0 30px 0;

				@media (min-width: @screen-sm) {
					margin: 64px 0 30px 0;
				}
			}
		}

		#button-sell {
			//padding: 13px 30px;
			text-transform: uppercase;
			.font-size(2.2);
			font-weight: normal;
			margin-bottom: 20px;

			@media (max-width: @screen-xs) {
				padding: 13px 30px;
				//.font-size(2.6);
			}
		}

		.fa-check {
			color: @ev-blue;
			margin-right: 10px;
			vertical-align: sub;
		}

		#prod-envirocash {
			width: 40px;
			margin-left: -6px;
		}

		#prod-damaged-phone {
			margin-top: 5px;
		}

		.info-row {
			min-height: 33px;
			margin-top: 5px;

			.info-icon {
				clear: both;
				float: left;
				margin: 0 10px 0px 15px;
			}

			.info-text {
				margin-left: 66px;
				position: relative;

				p {
					padding-top: 4px;
					margin-bottom: 0;
				}
			}

			.extra-text {
				padding: 5px 0;
				display: none;

				&.active {
					display: block;
				}
			}

			.see-more {
				color: #11b5e9;
				margin-bottom: 20px;
				display: block;

				&:hover {
					cursor: pointer;
				}
			}
		}
	}

	.model-Container {
		display: flex;
		justify-content: center;
		align-items: center;

		.StorageOptionDesc {
			text-transform: inherit;
		}

		.ListModels {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			.childModel {
				font-weight: 400;
				padding: 18px 44px;
				font-size: 2.6rem;
				text-transform: uppercase;
				margin: 0 0 10px 10px;
			}
		}
	}

	label {
		margin-bottom: 9px;
	}
}

.master-desc {
	margin-bottom: 40px;
}