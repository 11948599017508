﻿#carousel {
	&.inpost {
		margin-bottom: 0;
	}
}

#ec-section-white {
	text-align: center;
	max-width: 810px;
	padding: 10px 15px 10px 15px;

	@media (min-width: @screen-md) {
		padding: 30px 0 20px 0;
	}

	h2, p {
		margin-bottom: 30px;
	}
}

#ec-section-blue {
	text-align: center;
	background-color: @ev-blue;
	color: @white;
	padding: 20px 0 40px 0;

	@media (min-width: @screen-sm) {
		padding: 30px 0 40px 0;
	}

	h3 {
		color: @white;
		margin-bottom: 30px;
		.font-size(1.8);
	}

	h2 {
		color: @white;
		margin-bottom: 10px;
	}

	.icon {
		margin: 30px auto;
		width: 150px;
		height: 150px;
		background-size: 150px;
		background-size: cover;

		&.prep-it {
			background-image: url('@{img-cdn}icons/blue/icons_usp_prepit.svg');
		}

		&.bag-it {
			background-image: url('@{img-cdn}icons/blue/icons_usp_packit.svg');
		}

		&.scan-it {
			background-image: url('@{img-cdn}icons/blue/icons_usp_bagit.svg');
		}

		&.send-it {
			background-image: url('@{img-cdn}icons/blue/icons_usp_postit.svg');
		}
	}
}