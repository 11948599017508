﻿.damaged-phones-section {
    padding: 20px;
    text-align: left;
    text-align: center;

    .hidden-under-xs {
        @media screen and (max-width: 669px) {
            display: none;
        }
    }

    .icon-large {
        width: 200px;
        height: 200px;
        margin-left: 40px;
        margin-top: 40px;
        margin-bottom: 40px;
        background-size: 200px;

        &.example {
            border: 1px solid;
        }

        &.broken {
            background-image: url('@{img-cdn}icons/navy/phone-broken.svg');
        }

        &.tools {
            background-image: url('@{img-cdn}icons/navy/phone-tools.svg');
        }

        &.recycle {
            background-image: url('@{img-cdn}icons/navy/phone-recycle.svg');
        }

        &.tick {
            background-image: url('@{img-cdn}icons/navy/phone-tick.svg');
        }

        &.speaker {
            background-image: url('@{img-cdn}icons/navy/phone-speaker.svg');
        }

        &.charity {
            background-image: url('@{img-cdn}icons/navy/hands-with-heart.svg');
        }

        &.data {
            background-image: url('@{img-cdn}icons/navy/phone-data.svg');
        }

        &.handshake {
            background-image: url('@{img-cdn}icons/navy/phone-handshake.svg');
        }
    }

    .icon-xs {
        margin: 0 auto;

        @media screen and (min-width: 670px) {
            display: none;
        }
    }

    table {
        text-align: left;
    }

    h1 {
        text-transform: capitalize;
        margin-bottom: 40px;

        &.first-below {
            margin-bottom: 0;
        }
    }

    p {
        margin-bottom: 40px;

        &.first {
            margin-top: 40px;
        }

        &.center {
            text-align: center;
        }
    }

    strong {
        span {
            color: @charity-green;
        }
    }

    select[data-chosen="0"] {
        color: #999;
    }

    option {
        color: #555;
    }

    .btn {
        .font-size(1.6);
        text-transform: uppercase;
        color: @white;
    }

    &.red {
        background-color: @ev-red;
        color: @white;

        h1 {
            color: @white;
        }
    }

    &.blue {
        background-color: @ev-blue;
        color: @white;

        h1 {
            color: @white;
        }
    }

    &.charity {
        background-color: @charity-green;
        color: @white;

        h1 {
            color: @white;
            margin-bottom: 0;
        }
    }

    &.right {
        @media screen and (min-width: 670px) {
            table {
                text-align: right;

                .icon-large {
                    margin-right: 40px;
                    margin-left: 0;
                }
            }
        }
    }

    h1 {
        @media screen and (max-width: 669px) {
            text-align: center;
        }
    }

    &.header {
        background-color: @carousel-color;

        .container {
            background-color: @carousel-color;
            position: relative;

            h1 {
                text-align: left;
                .font-size(3.5);
                .line-height(5.5);
                color: @white;
                text-transform: uppercase;
                min-width: 250px;
                width: 50%;
                box-sizing: border-box;
                padding-left: 10px;
                margin-top: 40px;
            }

            span {
                background-color: @ev-red;
                padding: 10px 0 10px 0;
                position: relative;
                box-shadow: 10px 0 0 @ev-red, -10px 0 0 @ev-red;
            }
        }
    }

    &.what-counts {
        background-color: @ev-blue;
        color: @white;

        h1 {
            color: @white;
        }

        .icon {
            width: 150px;
            height: 150px;
            background-size: 150px;

            @media screen and (max-width: 479px) {
                width: 100px;
                height: 100px;
                background-size: 100px;
            }
        }
    }

    .icon {
        margin: 0 auto;
        margin-bottom: 10px;
        width: 100px;
        height: 100px;
        background-size: 100px;
        background-position-x: 0;
        background-position-y: 0;

        &.example {
            border: 1px solid;
        }

        &.sad {
            background-image: url('@{img-cdn}icons/navy/phone-sad.svg');
        }

        &.broken {
            background-image: url('@{img-cdn}icons/navy/phone-broken.svg');
        }

        &.no-power {
            background-image: url('@{img-cdn}icons/navy/phone-no-power.svg');
        }

        &.lcd {
            background-image: url('@{img-cdn}icons/navy/phone-lcd.svg');
        }

        &.no-buttons {
            background-image: url('@{img-cdn}icons/navy/phone-no-buttons.svg');
        }

        &.speaker {
            background-image: url('@{img-cdn}icons/navy/phone-speaker.svg');
        }

        &.no-camera {
            background-image: url('@{img-cdn}icons/navy/phone-no-camera.svg');
        }

        &.low-battery {
            background-image: url('@{img-cdn}icons/navy/phone-low-battery.svg');
        }
    }

    ul {
        padding: 0;
        list-style-type: none;
        margin-bottom: 40px;

        li:before {
            content: "\2022\0020";
        }
    }
}
