﻿
.breadcrumbs-wrapper {
    display: block;
    background: #F5F5F5;

    .breadcrumb {
        background: #F5F5F5;
        margin: 0;
        padding: 0;

        li {
            display: block;
            float: left;

            &:first-of-type, &:last-of-type {
                @media (max-width: 767px) {
                    display: block;
                }
            }

            .bc {
                color: #E31A22;
                margin-left: 10px;
                margin-right: 10px;
                font-size: 14px;
                line-height: 30px;

                @media (max-width: 767px) {
                    font-size: 11px;
                }
            }

            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    .breadcrumb > li + li {
        position: relative;
        padding-left: 15px;
    }

    .breadcrumb > li + li:before {
        content: '\f105';
        font-family: FontAwesome;
        color: #E31A22;
        position: absolute;
        width: 15px;
        line-height: 30px;
        text-align: center;
        left: 0;
    }
}
