﻿.charity-section {
    padding: 20px;
    text-align: center;

    h1 {
        color: @charity-green;
        text-transform: capitalize;
        margin-bottom: 40px;
    }

    p {
        margin-bottom: 40px;
    }

    strong {
        span {
            color: @charity-green;
        }
    }

    select[data-chosen="0"] {
        color: #999;
    }

    option {
        color: #555;
    }

    .btn {
        .font-size(1.6);
        background-color: @charity-green;
        border-color: @charity-green;
        text-transform: uppercase;
        color: @white;
    }

    &.header {
        background-image: linear-gradient(60deg, #2cb6e7 27.27%, #54cffb 27.27%, #54cffb 50%, #2cb6e7 50%, #2cb6e7 77.27%, #54cffb 77.27%, #54cffb 100%);
        background-size: 127.02px 220.00px;

        .container {
            background-image: url('@{img-cdn}icons/navy/hands-with-heart.svg');
            background-size: auto 80%;
            background-repeat: no-repeat;
            background-position: right 25px center;
            position: relative;

            @media only screen and (max-width: 459px) {
                background: none;

                h1 {
                    width: 90%;
                }
            }

            h1 {
                text-align: left;
                .font-size(3.5);
                .line-height(5.5);
                color: @white;
                text-transform: uppercase;
                min-width: 250px;
                width: 50%;
                box-sizing: border-box;

                span {
                    .line-height(6.5)
                }
            }

            span {
                background-color: @charity-green;
                padding: 10px;
                position: relative;
            }

            span:after {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                width: 0;
                height: 0;
                border: 20px solid transparent;
                border-right-color: @charity-green;
                border-left: 0;
                border-bottom: 0;
                margin-top: 5px;
                margin-left: -15px;
            }


            @media only screen and (max-width: 480px) {
                h1 {
                    .font-size(2.5);
                    .line-height(3.5);

                    span {
                        .line-height(4.5);
                    }

                    margin-left: 20px;
                    margin-bottom: 20px;
                }

                span {
                    background-color: @charity-green;
                    padding: 5px;
                    position: relative;
                }

                span:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 0;
                    height: 0;
                    border: 10px solid transparent;
                    border-right-color: @charity-green;
                    border-left: 0;
                    border-bottom: 0;
                    margin-top: 5px;
                    margin-left: -10px;
                }
            }
        }
    }

    &.header-index {
        .container {
            @media only screen and (max-width: 614px) and (min-width: 481px) {
                background: none;

                h1 {
                    width: 80%;
                }
            }
        }
    }

    &.header-registration {
        .container {
            @media only screen and (max-width: 544px) and (min-width: 481px) {
                background: none;

                h1 {
                    width: 80%;
                }
            }
        }
    }

    &.intro {
        height: 100%;
        position: relative;
    }

    &.intro::after {
        content: "";
        background: url('@{img-cdn}pages/charity/heart.png');
        background-size: cover;
        background-position: center center;
        opacity: 0.1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }

    &.how-it-works {
        background-color: @ev-blue;
        color: @white;

        h1 {
            color: @white;
        }

        .icon {
            width: 75px;
            height: 75px;
        }
    }

    &.received {
        height: 100%;
        position: relative;
    }

    &.received::after {
        content: "";
        background: url('@{img-cdn}pages/charity/donate.png');
        background-size: cover;
        background-position: center center;
        opacity: 0.1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }

    &.flow {
        text-align: left;
        color: @charity-green;

        .title {
            margin-bottom: 40px;
        }

        span {
            box-sizing: border-box;
            background-color: @charity-green;
            color: @white;
            text-transform: capitalize;
            .font-size(3.5);
            .line-height(5.5);
            padding: 10px;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
        }

        @media only screen and (max-width: 480px) {
            span {
                .font-size(2.5);
                .line-height(3.5);
                padding: 5px;
            }
        }

        .ec-arrow-left {
            background-image: url('@{img-cdn}icons/white/arrow.svg');
            height: 50px;
            width: 50px;
            margin: 0 auto;
            transform: scaleX(-1);
        }

        .ec-arrow-right {
            background-image: url('@{img-cdn}icons/white/arrow.svg');
            height: 50px;
            width: 50px;
            margin: 0 auto;
        }

        .register-charity {
            text-align: center;
            margin-bottom: 0;
        }
    }

    &.registration {
        @media only screen and (min-width: 1200px) {
            .col-reg {
                margin-left: 12.5%;
            }
        }

        .col-intro {
            text-align: left;
        }

        h1 {
            text-transform: none;
            color: @black;

            span {
                color: @charity-green;
            }
        }

        .registration-container {
            padding: 20px;
            padding-bottom: 10px;
            background-color: @grey;
            text-align: left;
            margin-bottom: 20px;

            h2 {
                padding: 0;
                margin: 0;
                padding-bottom: 20px;
                border-bottom: 1px solid @black;
                margin-bottom: 20px;
            }
        }

        .btn-register {
            width: 100%;
            .font-size(2);
            padding: 15px;

            &.disabled {
                background-color: @dark-grey;
                border-color: @dark-grey;
            }
        }

        .btn-address {
            width: 100% !important;
            text-transform: capitalize;
        }

        .required {
            position: relative;
        }

        .required::after {
            content: " *";
            color: @ev-red;
            position: absolute;
            top: 10px;
            right: 10px;
        }

        .required-select::after {
            content: " *";
            color: @ev-red;
            position: absolute;
            top: 10px;
            right: 20px;
        }

        @media only screen and (max-width: 992px) {
            .registration-container {
                background-color: @white;
                padding: 0;
                margin-bottom: 40px;
            }

            .col-intro {
                padding-left: 30px;
                padding-right: 30px;


                h1 {
                    margin-bottom: 20px;
                }
            }
        }
    }

    &.complete {
        text-align: left;

        h1 {
            text-transform: none;
            color: @black;
            margin-bottom: 0;

            span {
                color: @charity-green;
            }
        }

        h2 {
            span {
                color: @charity-green;
                font-weight: 700;
            }
        }

        .what-happens-next {
            text-align: center;

            .head-with-tail {
                background-color: @charity-green;
                margin: 0 auto;
                margin-bottom: 40px;
                margin-top: 10px;
            }

            .head-with-tail::after {
                content: '';
                line-height: 0;
                font-size: 0;
                width: 0;
                height: 0;
                border-top: 0 solid @charity-green;
                border-bottom: 19px solid transparent;
                border-left: 32px solid @charity-green;
                border-right: 0 solid transparent;
                position: absolute;
                bottom: -19px;
                left: 20px;
            }
        }
    }

    .icon {
        margin: 0 auto;
        margin-bottom: 10px;
        width: 100px;
        height: 100px;

        &.hands-with-heart {
            background-image: url('@{img-cdn}icons/green/hands-with-heart.svg');
        }

        &.recycle {
            background-image: url('@{img-cdn}icons/green/recycle.svg');
        }

        &.iphone-sparkle {
            background-image: url('@{img-cdn}icons/green/iphone-sparkle.svg');
        }

        &.spanner {
            background-image: url('@{img-cdn}icons/green/spanner.svg');
        }

        &.basket-with-hearts {
            background-image: url('@{img-cdn}icons/white/basket-with-hearts.svg');
        }

        &.donation-tick {
            background-image: url('@{img-cdn}icons/white/donation-tick.svg');
        }

        &.letter-with-heart {
            background-image: url('@{img-cdn}icons/white/letter-with-heart.svg');
        }

        &.search {
            background-image: url('@{img-cdn}icons/white/search.svg');
        }
    }

    .g-recaptcha {
        margin-bottom: 20px;
        display: inline-block;
    }
}
